import React, { useEffect, useRef, useState } from "react";
import styles from "./styles.module.scss";
import ImgBack from "../../../components/Image/ImgBack";

function TeacherCard({ data, className, onClick }) {
  const { img, title } = data;
  const [size, setSize] = useState(25);
  const ref = useRef(null);

  const GoToTeacher = () => {
    onClick(data);
  };

  useEffect(() => {
    if (ref && ref.current) {
      setSize(ref.current.offsetWidth * 0.08);
    }
  }, [ref]);

  return (
    <div
      ref={ref}
      className={`${styles.main} ${className}`}
      onClick={GoToTeacher}
    >
      <ImgBack src={img} alt="" />

      <h2 style={{ fontSize: `${size}px` }}>{title}</h2>
    </div>
  );
}

export default TeacherCard;
