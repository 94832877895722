import React from "react";
import styles from "./styles.module.scss";
import RightColoredTitle from "../../../../components/RightColoredTitle/RightColoredTitle";

import { InscrireBtn2 } from "../../../../components/buttons/Buttons";
import { useNavigate } from "react-router-dom";
import ImgBack from "../../../../components/Image/ImgBack";

function Apropos({ a_propos }) {
  const navigate = useNavigate();

  const toInscription = () => {
    navigate("/inscription");
  };

  return (
    <div className={styles["main"]}>
      <div className={styles["header"]}>
        <RightColoredTitle>{a_propos.title}</RightColoredTitle>
        <ImgBack src={a_propos.logo} className={styles["logo"]} alt="" />
      </div>

      <div className={styles["body"]}>
        <div className={styles["setion1"]}>
          <p
            className={styles["p"]}
            dangerouslySetInnerHTML={{ __html: a_propos.description }}
          />
        </div>

        {a_propos.content && (
          <div
            className={styles["setion2"]}
            dangerouslySetInnerHTML={{ __html: a_propos.content }}
          />
        )}

        <div className={styles["elements"]}>
          {(a_propos?.showed_deplomes || []).map((dep, key) => {
            return <span key={key}>{dep}</span>;
          })}
        </div>

        <div className={styles["button"]}>
          <InscrireBtn2 onClick={toInscription}>
            S'inscrire maintenant !
          </InscrireBtn2>
        </div>
      </div>
    </div>
  );
}

export default Apropos;
