import keys from "./colors.keys";

const ChangeTheme = (theme) => {
  return async (dispatch) => {
    dispatch({
      type: keys.change_theme,
      value: theme,
    });
  };
};

export { ChangeTheme };
