export function divideArrayIntoSubarrays(arr, subarraySize) {
  if (
    !Array.isArray(arr) ||
    !Number.isInteger(subarraySize) ||
    subarraySize <= 0
  ) {
    throw new Error(
      "Invalid input: The first argument must be an array and the second argument must be a positive integer."
    );
  }

  const result = [];
  for (let i = 0; i < arr.length; i += subarraySize) {
    result.push(arr.slice(i, i + subarraySize));
  }

  return result;
}

export function createArray(n) {
  return new Array(n).fill(0);
}

export const Get_n_element = (array = [], n) => {
  return array.filter((_, key) => key < n);
};
