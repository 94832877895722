export function DateDoted(dateString) {
  let date = new Date(dateString);
  let days = date.getDate() < 10 ? "0" + date.getDate() : "" + date.getDate();
  let months =
    date.getMonth() + 1 < 10
      ? "0" + (date.getMonth() + 1)
      : "" + (date.getMonth() + 1);
  let years = date.getFullYear();

  return `${days} . ${months} . ${years}`;
}
