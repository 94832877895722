import img1 from "../../assets/images/partners/1.png";
import img2 from "../../assets/images/partners/2.jpg";
import img3 from "../../assets/images/partners/3.png";
import img4 from "../../assets/images/partners/4.png";
import img5 from "../../assets/images/partners/5.png";
import img6 from "../../assets/images/partners/6.jpg";
import img7 from "../../assets/images/partners/7.gif";
import img8 from "../../assets/images/partners/8.png";
import img9 from "../../assets/images/partners/9.jpg";
import img10 from "../../assets/images/partners/10.png";
import img11 from "../../assets/images/partners/11.png";
import img12 from "../../assets/images/partners/12.jpg";
import img13 from "../../assets/images/partners/13.jpg";
import img14 from "../../assets/images/partners/14.jpg";
import img15 from "../../assets/images/partners/15.png";
import img16 from "../../assets/images/partners/16.png";
import img17 from "../../assets/images/partners/17.jpg";
import img18 from "../../assets/images/partners/18.png";
import img19 from "../../assets/images/partners/19.jpg";
import img20 from "../../assets/images/partners/20.gif";

export const data = [
  img1,
  img2,
  img3,
  img4,
  img5,
  img6,
  img7,
  img8,
  img9,
  img10,
  img11,
  img12,
  img13,
  img14,
  img15,
  img16,
  img17,
  img18,
  img19,
  img20,
];
