import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import TitleUndLine from "../../../../components/PageTitles/TitleUndLine/TitleUndLine";
import { FadeUp } from "../../../../components/Animations/Fade";

import filter from "../../../../assets/images/filter_event.png";
import arrow_white from "../../../../assets/svgs/icons/arrow_white.svg";
import SeeMore from "../../../../components/SeeMore/SeeMore";

import { events } from "../../../CAMPUS/SubPages/Actuality/events";
import { DateDoted } from "../../../../functions/Data";
import { useNavigate } from "react-router-dom";
import customAxios from "../../../../utils/axios";
import ImgBack from "../../../../components/Image/ImgBack";

function Actuality() {
  return (
    <div className={styles["main"]}>
      <FadeUp>
        <TitleUndLine>Actualités</TitleUndLine>
        <Events />
      </FadeUp>
    </div>
  );
}

function Events() {
  const navigate = useNavigate();
  const [evets, setEvets] = useState([]);

  useEffect(() => {
    customAxios.get("/Event/getAll").then((res) => {
      setEvets(res?.data?.Actuality || []);
    });
  }, []);

  const GoToEvent = (id) => {
    navigate(`/campus/actualites/${id}`);
  };

  const GoToEvents = () => {
    navigate("/campus/actualites");
  };

  return (
    <FadeUp>
      <div className={styles["events"]}>
        {evets?.length > 0 && (
          <BigEvents
            image={evets[0].main_img}
            title={evets[0].title}
            date={DateDoted(evets[0].date)}
            onClick={() => {
              GoToEvent(evets[0]._id);
            }}
          />
        )}

        <div className={styles["rest-events"]}>
          {evets?.length > 1 && (
            <MediumEvent
              image={evets[1].main_img}
              title={evets[1].title}
              date={DateDoted(evets[1].date)}
              onClick={() => {
                GoToEvent(evets[1]._id);
              }}
            />
          )}

          <div className={styles["rest-events2"]}>
            {evets?.length > 2 && (
              <SmallEvent
                image={evets[2].main_img}
                title={evets[2].title}
                date={DateDoted(evets[2].date)}
                onClick={() => {
                  GoToEvent(evets[2]._id);
                }}
              />
            )}

            {evets?.length > 3 && (
              <SmallEvent
                image={evets[3].main_img}
                title={evets[3].title}
                date={DateDoted(evets[3].date)}
                onClick={() => {
                  GoToEvent(evets[3]._id);
                }}
              />
            )}
          </div>
        </div>
      </div>

      <div className={styles["see-more"]}>
        <SeeMore grey_icon={true} blue_text3={true} onClick={GoToEvents}>
          Voir plus d'informations
        </SeeMore>
      </div>
    </FadeUp>
  );
}

function BigEvents({ image, title, date, onClick = () => {} }) {
  return (
    <div className={`${styles["big-event"]} ${styles["event"]}`}>
      <ImgBack src={image} alt="event" className={styles["event-img"]} />
      <img src={filter} alt="event" className={styles["event-filter"]} />
      <div className={styles["content"]}>
        <h2>{title}</h2>
        <div className={styles["description"]}>
          <span>{date}</span>
          <img src={arrow_white} onClick={onClick} alt={title} />
        </div>
      </div>
    </div>
  );
}

function MediumEvent({ image, title, date, onClick = () => {} }) {
  return (
    <div className={`${styles["medium-event"]} ${styles["event"]}`}>
      <ImgBack src={image} alt="event" className={styles["event-img"]} />
      <img src={filter} alt="event" className={styles["event-filter"]} />
      <div className={styles["content"]}>
        <h2>{title}</h2>
        <div className={styles["description"]}>
          <span>{date}</span>
          <img src={arrow_white} onClick={onClick} alt={title} />
        </div>
      </div>
    </div>
  );
}

function SmallEvent({ image, title, date, onClick = () => {} }) {
  return (
    <div className={`${styles["small-event"]} ${styles["event"]}`}>
      <ImgBack src={image} alt="event" className={styles["event-img"]} />
      <img src={filter} alt="event" className={styles["event-filter"]} />
      <img src={filter} alt="event" className={styles["event-filter"]} />
      <img src={filter} alt="event" className={styles["event-filter"]} />
      <div className={styles["content"]}>
        <h2>{title}</h2>
        <div className={styles["description"]}>
          <span>{date}</span>
          <img src={arrow_white} onClick={onClick} alt={title} />
        </div>
      </div>
    </div>
  );
}

export default Actuality;
