import React, { useRef } from "react";
import styles from "./styles.module.scss";
import ImgBack from "../../../components/Image/ImgBack";
import ScrollNow from "../../../components/ScrollNow/ScrollNow";
import useResolution from "../../../hooks/useResolution";

function ImageSection({ url, title = "" }) {
  const ref = useRef(null);
  const { sm } = useResolution();

  const ScrollScreen = () => {
    let offset = sm ? 60 : 0;
    window.scrollTo({
      top: (ref?.current.offsetHeight || window.innerHeight) - offset,
      behavior: "smooth",
    });
  };

  return (
    <div className={styles["main"]} ref={ref}>
      <div className={styles["video-container"]}>
        <ImgBack src={url} alt="Formation" />
      </div>
      <div className={`${styles["filter2"]}`}></div>
      <div className={styles["filter1"]} />
      <div className={styles["content"]}>
        <h1>{title}</h1>

        <ScrollNow onClick={ScrollScreen} />
      </div>
    </div>
  );
}

export default ImageSection;
