import React, { useEffect, useRef, useState } from "react";
import styles from "./styles.module.scss";
import TitleUndLine from "../../../../components/PageTitles/TitleUndLine/TitleUndLine";

import filter1 from "../../../../assets/images/meet_students/filter.webp";
import filter2 from "../../../../assets/images/meet_students/filter2.png";
import play from "../../../../assets/svgs/icons/play.svg";
import pause from "../../../../assets/svgs/icons/pause.svg";
import { FadeUp } from "../../../../components/Animations/Fade";
import { ZoomIn } from "../../../../components/Animations/Zoom";

import { Swippers } from "../../../../components/Carossel/Carossel";

import interview1 from "../../../../assets/videos/interview1.mp4";
import interview2 from "../../../../assets/videos/interview2.mp4";
import interview3 from "../../../../assets/videos/interview3.mp4";

import imgvid1 from "../../../../assets/videos/vid1.webp";
import imgvid2 from "../../../../assets/videos/vid2.webp";
import imgvid3 from "../../../../assets/videos/vid3.webp";
import useResolution from "../../../../hooks/useResolution";

function MeetTheStudent() {
  const { sm } = useResolution();

  return (
    <div className={styles["main"]}>
      <FadeUp>
        <TitleUndLine color="white">Etudiants De l'UIK</TitleUndLine>
      </FadeUp>
      {sm && <StudentsMobile />}
      {!sm && <Students />}
    </div>
  );
}

function Students(props) {
  return (
    <div className={styles["students"]}>
      {/* <Student type="hidden" image={student1} title="Design" /> */}
      <Student
        type="hidden"
        image={interview3}
        fisrtImage={imgvid3}
        title="Informatique"
      />
      <Student
        type="visible"
        image={interview1}
        fisrtImage={imgvid1}
        title="Architecture"
      />
      <Student
        type="hidden"
        image={interview2}
        fisrtImage={imgvid2}
        title="Design"
      />
      {/* <Student type="hidden" image={student2} title="Informatique" /> */}
    </div>
  );
}

function StudentsMobile(props) {
  const videos = [interview1, interview3, interview2];
  const images = [imgvid1, imgvid3, imgvid2];
  const [cr, setCr] = useState(0);

  return (
    <div className={styles["students"]}>
      <Student
        type="visible"
        image={videos[cr]}
        fisrtImage={images[cr]}
        title="---"
      />
      <br />
      <Swippers current={cr} setCurrent={setCr} size={videos.length} />
    </div>
  );
}

function Student(props) {
  const { type, image, fisrtImage } = props;
  const [play_vid, setplay] = useState(false);
  const [fisrtPlay, setFirstPlay] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const videoRef = useRef(null);

  useEffect(() => {
    videoRef.current?.load();
    setplay(false);
  }, [image]);

  const handlePlayPause = () => {
    setFirstPlay(true);
    if (videoRef?.current?.paused) {
      videoRef.current.play();
      setplay(true);
    } else {
      videoRef.current.pause();
      setplay(false);
    }
  };

  useEffect(() => {
    window.addEventListener("load", () => {
      setLoaded(true);
    });
  }, []);

  return (
    <ZoomIn className={`${styles["student"]} ${styles[type]}`}>
      {loaded && (
        <video ref={videoRef} pla loop className={styles["image"]}>
          <source src={image} type="video/mp4" />
        </video>
      )}
      {!fisrtPlay && (
        <img src={fisrtImage} alt="" className={styles["image"]} />
      )}
      <img
        src={filter2}
        className={`${styles["image"]} ${styles["filter"]}`}
        alt=""
      />
      <img src={filter1} alt="" className={styles["image"]} />
      <div className={styles["content"]}>
        <span />

        <img
          src={play_vid ? pause : play}
          onClick={handlePlayPause}
          className={play_vid ? styles.is_playing : ""}
          alt="students"
        />

        <span />
      </div>
    </ZoomIn>
  );
}

export default MeetTheStudent;
