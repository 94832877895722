import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import MainNavbar from "../../layouts/Navbars/main_navbar/MainNavbar";
import SecondNavbar from "../../layouts/Navbars/second_navbar/SecondNavbar";
import ContactSection from "../../layouts/ContactSecion/ContactSection";
import Footer from "../../layouts/Footer/Footer";

import t3 from "../../assets/images/teachers/RealTeachers/director1.jpg";

import TeacherCard from "../../components/Cards/TeacherCard/Teachers";

import { ZoomIn } from "../../components/Animations/Zoom";

import { divideArrayIntoSubarrays } from "../../functions/Arrays";
import { useNavigate } from "react-router-dom";
import customAxios from "../../utils/axios";

function Director() {
  const [carossel, setcarossel] = useState(0);
  const [showed_teachers, setShowed_teachers] = useState([[]]);
  const navig = useNavigate();

  const [equipe, setEauipe] = useState([]);

  useEffect(() => {
    customAxios
      .get("/Teacher/getAll")
      .then((res) => {
        console.log(res.data.Person);
        setEauipe(res.data.Person);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handle_select = (item) => {
    setcarossel(item);
  };

  useEffect(() => {
    let all = [...equipe];
    setShowed_teachers(divideArrayIntoSubarrays(all, 4));
  }, [equipe]);

  const GoToOne = (teach) => {
    if (teach.isDirector) {
      navig("/director");
    } else {
      navig(`/faculty/${teach.link_title}`);
    }
  };

  return (
    <div>
      <MainNavbar />
      <SecondNavbar isCoched />
      <div className={styles["main"]}>
        <div className={styles["container"]}>
          <div className={styles["teacher"]}>
            <div className={styles["name"]}>
              <span className={styles["teacher-name"]}>M.LOTFI CHERIF</span>
              <span className={styles["teacher-name-2"]}>
                PRESIDENT-FONDATEUR
              </span>

              <ZoomIn className={styles["user-img-mobile"]}>
                <img src={t3} alt="" />
              </ZoomIn>

              <p>
                L'Université Ibn Khaldoun est née de la volonté de participer à
                l'effort d'édification de la société du savoir de demain.
                L'enseignement à l'UIK repose sur une pédagogie active et
                interactive, fondée sur une très forte implication des étudiants
                dans tous les projets de l'université. Active, parce qu’elle
                repose sur un apprentissage de l'entreprise à travers des
                expériences pratiques. Interactive, parce qu’elle considère
                chaque étudiant comme une personnalité à part entière, avec des
                dispositions et des motivations qui lui appartiennent.
              </p>

              <p>
                Dans un monde en constante évolution, le digital est cœur de
                toutes nos formations. Fort de ses 18 ans d’expérience,
                l'université Ibn Khaldoun forme des étudiants capables
                d'appréhender les défis du monde numérique en constante
                évolution et de s'adapter aux nouvelles tendances
                technologiques.
              </p>
            </div>
            <ZoomIn className={styles["user-img"]}>
              <img src={t3} alt="" />
            </ZoomIn>
          </div>

          <div className={styles["other-teachers"]}>
            <hr />

            {showed_teachers?.length && (
              <div className={styles["teachers"]}>
                {showed_teachers[carossel].map((item, key) => {
                  return (
                    <TeacherCard
                      className={styles["teacher"]}
                      key={key}
                      data={item}
                      onClick={GoToOne}
                    />
                  );
                })}
              </div>
            )}

            <div className={styles["swipper-container"]}>
              <div className={styles["swipper"]}>
                {showed_teachers.map((item, key) => {
                  return (
                    <span
                      key={key}
                      className={`
                      ${styles.circle} 
                      ${key === carossel && styles.active}
                      `}
                      onClick={() => {
                        handle_select(key);
                      }}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
      <ContactSection />
      <Footer />
    </div>
  );
}

export default Director;
