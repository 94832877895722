import React from "react";
import ImgBack from "../../../components/Image/ImgBack";

function FormationCard({ formation, onClick = () => {} }) {
  return (
    <div class="h-full relative flex flex-col bg-clip-border rounded-xl bg-[#F9FBFC] text-gray-700 w-full shadow-xl border">
      <div className="w-full flex-grow">
        <div class="relative bg-clip-border mt-4 mx-4 rounded-xl overflow-hidden bg-blue-gray-500 text-white shadow-blue-gray-500/40 shadow-lg">
          <ImgBack src={formation.mainImg} alt="ui/ux review check" />
          <div class="flex items-start justify-start flex-col md:flex-row md:items-end md:justify-between p-4 absolute inset-0 h-full w-full bg-[#00000022] from-transparent via-transparent to-black/60 ">
            <h3 className="bg-[#0AAEDB] px-2 py-1 rounded-md mb-2 md:mb-0">
              {formation.studyType}
            </h3>

            <h3 className="bg-[#6E3699] px-2 py-1 rounded-md mb-2 md:mb-0">
              {formation.durationValue}
            </h3>

            <h3 className="bg-[#AB1A2D] px-2 py-1 rounded-md mb-2 md:mb-0">
              {formation.durationInHours}
            </h3>
          </div>
        </div>

        <div class="p-6">
          <div class="mb-3 flex items-start justify-between">
            <h5 class="block antialiased tracking-normal font-sans text-xl leading-snug text-blue-gray-900 font-medium max-w-[80%]">
              {formation.title}
            </h5>
            <p class="antialiased font-sans text-xl font-bold leading-relaxed text-blue-gray-900 flex items-center gap-1.5">
              {formation.OnlinePrice} TND
            </p>
          </div>
          <p class="block antialiased font-sans text-base font-light leading-relaxed text-gray-700">
            {formation.description}
          </p>
        </div>
      </div>
      <div class="p-6 pt-0">
        <button
          onClick={onClick}
          class="align-middle select-none font-sans font-bold text-center uppercase transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-sm py-3.5 px-7 rounded-lg bg-[#0AAEDB] text-white shadow-md shadow-gray-900/10 hover:shadow-lg hover:shadow-gray-900/20 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none block w-full gap-3"
          type="button"
        >
          Voir Plus
        </button>
      </div>
    </div>
  );
}

export default FormationCard;
