import React from "react";
import styles from "./styles.module.scss";

import map from "../../assets/images/contact/map.png";
import logo from "../../assets/svgs/logos/logo_blue.svg";

import { email_data, location_data, phone_data } from "./fake-data";
import GoogleMapComponent from "../../components/GoogleMap/GoogleMap";

function ContactSection() {
  return (
    <div className={styles["main"]}>
      <div className={styles["left"]}>
        {/* <img     src={map} alt="" /> */}
        <GoogleMapComponent />
      </div>

      <div className={styles["right"]}>
        <img src={logo} alt="" className={styles["logo"]} />
        <div className={styles["flex"]}>
          <ContInfo data={email_data} />
          <ContInfo data={phone_data} />
        </div>

        <ContInfo data={location_data} />
      </div>
    </div>
  );
}

function ContInfo(props) {
  const { image, infos } = props.data;
  return (
    <div className={styles["cont-info"]}>
      <img src={image} alt="" />
      <div className={styles["infos"]}>
        {infos.map((info, key) => {
          return <h2 key={key}>{info}</h2>;
        })}
      </div>
    </div>
  );
}

export default ContactSection;
