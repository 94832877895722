import React, { useEffect } from "react";
import styles from "./styles.module.scss";

import PageContainer from "../../Common/PageContainer/PageContainer";
import { Routes, Route, useNavigate } from "react-router-dom";
import DemarchesAdmission from "./SubPages/DemarchesAdmission";
import EtudiantInternaux from "./SubPages/EtudiantInternaux";

function Admission(props) {
  const navig = useNavigate();
  const links = [
    {
      name: "Démarches d’admission",
      link: "/demarches_admission",
    },
    {
      name: "Etudiants Internationaux",
      link: "/etudiants_internationaux",
    },
  ];

  useEffect(() => {
    navig(`/admission/demarches_admission`);
  }, []);

  return (
    <div className={styles.main}>
      <PageContainer title="Admission" actual_link="/admission" links={links}>
        <Routes>
          <Route path="/demarches_admission" element={<DemarchesAdmission />} />
          <Route
            path="/etudiants_internationaux"
            element={<EtudiantInternaux />}
          />
        </Routes>
      </PageContainer>
    </div>
  );
}

export default Admission;
