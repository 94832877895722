import React, { useEffect, useState } from "react";
import styles from "../../../Common/DegreePage/styles.module.scss";
import LeftColoredTitle from "../../../components/Titles/LeftColoredTitle";
import CardInfo from "../../../components/Cards/CardInfo/CardInfo";
import styles2 from "./styles.module.scss";

import {
  OneColTable,
  ShowDangerHtml,
} from "../../../Common/DegreePage/DegreePage";

import {
  FaRegClock,
  FaExclamationTriangle,
  FaRegCalendarAlt,
} from "react-icons/fa";

import { FadeUp } from "../../../components/Animations/Fade";
import { useLocation } from "react-router-dom";

import CustomCardInfo from "../../../components/Cards/CardInfo/CustomCardInfo";
import LeftArrowedTitle from "../../../components/Titles/LeftArrowedTitle";
import ImgBack from "../../../components/Image/ImgBack";
import axios from "../../../utils/axios";
import { InscrireBtn2 } from "../../../components/buttons/Buttons";
import PopupInsc from "./PopupInsc/PopupInsc";

function FormationPage() {
  const location = useLocation();
  const [formation, setFormation] = useState(null);
  const [open, setOpen] = useState(false);

  const [data, setData] = useState([]);

  useEffect(() => {
    axios
      .get("/Formation/getAll")
      .then((res) => {
        setData(res.data.Formation);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    let name = location.pathname.replace("/formations/", "");

    let deg = data.find((item) => {
      return item.title.toLowerCase().split(" ").join("-") === name;
    });

    setFormation(deg);
  }, [location, data]);

  if (!formation) return null;

  return (
    <div className={`${styles.main} ${styles.main_alt}`}>
      {open && (
        <PopupInsc
          formation={formation}
          close={() => {
            setOpen(false);
          }}
        />
      )}
      <LeftColoredTitle>{formation?.title} :</LeftColoredTitle>
      {formation?.subTitle && (
        <p className={styles2.sub_title}>{formation?.subTitle}</p>
      )}

      {formation?.topImage && (
        <ImgBack
          className={styles2.topImage}
          src={formation?.topImage}
          alt={formation?.title}
        />
      )}

      <GlobalInfos formation={formation} />
      <br />

      <LeftArrowedTitle>
        {"Début de la prochaine session : "}
        <span className={styles2.blue_text}>
          {formation.startOfNextSession}
        </span>
      </LeftArrowedTitle>

      <ShowDangerHtml text={formation.long_desc} />
      <br />
      <LeftArrowedTitle>Compétences clés : </LeftArrowedTitle>
      <br />
      <ShowDangerHtml text={formation.competence_key} />
      <br />
      <LeftArrowedTitle>Programme : </LeftArrowedTitle>
      <br />
      <OneColTable data={formation.program} />

      <div className={styles2["button"]}>
        <InscrireBtn2
          onClick={() => {
            setOpen(true);
          }}
        >
          S'inscrire maintenant !
        </InscrireBtn2>
      </div>

      {/* {formation?.botImage && (
        <img
          className={styles2.botImage}
          src={formation?.botImage}
          alt={formation?.title}
        />
      )} */}
    </div>
  );
}

export const GlobalInfos = ({ formation }) => {
  return (
    <div className={styles["cards-infos"]}>
      <FadeUp className={styles["cards-cont"]}>
        <CardInfo title="Durée" Icon={FaRegClock}>
          <div className={styles2.card_container}>
            <p className={styles2.card_p}>{formation.durationValue}</p>
            <p className={styles2.card_p}>{formation.durationType}</p>
          </div>
        </CardInfo>
      </FadeUp>

      <FadeUp className={styles["cards-cont"]}>
        <CustomCardInfo title={"Volume horaire"} Icon={FaRegCalendarAlt}>
          <div className={styles2.card_container}>
            <p className={styles2.card_p}>{formation.durationInHours}</p>
            {formation.daysSpecifications.map((item, key) => {
              return (
                <p key={key} className={styles2.card_p}>
                  {item.day} :{" "}
                  <span className={styles2.blue_text}>{item.hours}</span>
                </p>
              );
            })}
          </div>
        </CustomCardInfo>
      </FadeUp>

      <FadeUp className={styles["cards-cont"]}>
        <CustomCardInfo
          title={"Prix de formation"}
          Icon={FaExclamationTriangle}
        >
          <div className={styles2.card_container}>
            <p className={styles2.card_p}>
              Présentiel :{" "}
              <span className={styles2.blue_text}>
                {formation.PresentialPrice} TND
              </span>
            </p>
            <p className={styles2.card_p}>
              En ligne :{" "}
              <span className={styles2.blue_text}>
                {formation.OnlinePrice} TND
              </span>
            </p>
          </div>
        </CustomCardInfo>
      </FadeUp>
    </div>
  );
};

export const PresentationSec = ({ formation }) => {
  return (
    <div>
      <div className={styles["pres-sec"]}>
        <p className={styles["descript"]}>{formation.long_desc}</p>
      </div>
    </div>
  );
};

export default FormationPage;
