import React, { useEffect, useState } from "react";
import styles from "./Alternance.module.scss";
import PageContainer from "../../Common/PageContainer/PageContainer";
import FormationCard from "./FormationCard/FormationCard";
import { deplomes } from "./data";
import LeftColoredTitle from "../../components/Titles/LeftColoredTitle";
import { useNavigate } from "react-router-dom";
import axios from "../../utils/axios";

function AllFormations() {
  const navig = useNavigate();

  const [data, setData] = useState([]);

  useEffect(() => {
    axios
      .get("/Formation/getAll")
      .then((res) => {
        console.log("formations");
        console.log(res.data.Formation);
        setData(res.data.Formation);
        console.log("formations");
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const GoToFormation = (formation) => {
    navig(`/formations/${formation.title.toLowerCase().split(" ").join("-")}`);
  };

  return (
    <div>
      <PageContainer title="FORMATIONS" links={[]} with_links={false}>
        <div className={styles.container}>
          <LeftColoredTitle>Nos formations</LeftColoredTitle>
          <div className="w-full flex flex-row flex-wrap items-stretch justify-center mt-8">
            {data.map((formation, index) => {
              return (
                <div
                  key={index}
                  className="mb-4 w-full lg:w-full xl:w-[48%] xxl:w-[31%] mx-[1%]"
                >
                  <FormationCard
                    onClick={() => GoToFormation(formation)}
                    formation={formation}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </PageContainer>
    </div>
  );
}

export default AllFormations;
