import React from "react";
import styles from "./styles.module.scss";
import { AiOutlineLoading } from "react-icons/ai";

export const InscrireBtn = ({
  children,
  onClick = () => {},
  className = "",
  loading = false,
  txt_loading = "",
}) => {
  return (
    <button
      className={`${styles["inscrire"]} ${className} ${
        loading && styles.loading
      }`}
      onClick={() => {
        if (!loading) {
          onClick();
        }
      }}
    >
      {!loading && children}
      {loading && (
        <AiOutlineLoading
          className={`${styles["icon"]} ${styles["turning"]}`}
        />
      )}
      {loading && txt_loading}
    </button>
  );
};

export const InscrireBtn2 = ({
  children,
  onClick = () => {},
  loading = false,
  txt_loading = "",
}) => {
  return (
    <button
      className={`${styles["inscrire2"]} ${loading && styles.loading}`}
      disabled={loading}
      onClick={() => {
        if (!loading) {
          onClick();
        }
      }}
    >
      {!loading && children}
      {loading && (
        <AiOutlineLoading
          className={`${styles["icon"]} ${styles["turning"]}`}
        />
      )}
      {loading && txt_loading}
    </button>
  );
};
