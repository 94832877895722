import React, { useEffect, useRef, useState } from "react";
import useOnScreen from "../../hooks/useOnViewReveal";
import CountUp from "react-countup";

function CountDownOnShow(props) {
  const { number } = props;
  const ref = useRef(null);
  const showed = useOnScreen(ref);
  const [once, setOnce] = useState(false);

  useEffect(() => {
    if (ref && showed && !once) {
      setOnce(true);
      ref.current.click();
    }
  }, [showed, ref]);

  return (
    <CountUp start={0} end={number} duration={4} delay={0.2}>
      {({ countUpRef, start }) => (
        <span ref={ref} onClick={start}>
          <span ref={countUpRef} />
        </span>
      )}
    </CountUp>
  );
}
export default CountDownOnShow;
