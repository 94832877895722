import email from "../../assets/svgs/icons/contact/email.png";
import location from "../../assets/svgs/icons/contact/location.png";
import phone from "../../assets/svgs/icons/contact/phone.png";

export const email_data = { image: email, infos: ["admission@uik.ens.tn"] };
export const phone_data = {
  image: phone,
  infos: ["+216 97 432 463", "+216 54 083 083"],
};
export const location_data = {
  image: location,
  infos: [
    "Rue Jawdat Alhayat, La Soukra, Chotrana - Ariana 2036",
    "Fax : 70 694 194",
    "Rue Elless, Montplaisir - Tunis 1073",
    "Fax : 71 901 721",
  ],
};
