import React from "react";
import styles from "./styles.module.scss";
import ImgBack from "../../../components/Image/ImgBack";
import { useParams } from "react-router-dom";

function VideoSection({ data }) {
  const { id } = useParams();

  return (
    <div className={styles["main"]}>
      <div className={styles["video-container"]}>
        <ImgBack
          src={id ? data?.a_propos?.inter_img : data?.a_propos?.outer_img}
          alt="UIK"
        />
      </div>
      <div className={styles["filter2"]} />
      <div className={styles["filter1"]} />
    </div>
  );
}

export default VideoSection;
