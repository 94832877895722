import React, { useState } from "react";
import styles from "./styles.module.scss";
import LeftColoredTitle from "../../../../components/Titles/LeftColoredTitle";
import down_icon from "../../../../assets/svgs/icons/down.svg";
import { HR1 } from "../../../../components/HRs/HRs";
import { NavLink } from "react-router-dom";

function FAQ() {
  return (
    <div className={styles.main}>
      <LeftColoredTitle>FAQ</LeftColoredTitle>

      <div className={styles.container2}>
        <QA question="Est-ce que vos diplômes sont reconnus internationalement ?">
          <p>
            Le diplôme délivré par l’UIK est un diplôme d’État et par conséquent
            est reconnu à l’échelle internationale. Cependant les critères et
            les procédures d’admission dans certaines institutions étrangères
            restent à leur discrétion.
          </p>
        </QA>

        <QA question="Existe-t-il plusieurs sessions d’admission ?">
          <p>
            Il n’existe qu’une seule session d’admission en automne
            (mi-septembre). Les inscriptions se prolongent jusqu’au mois de
            Novembre.
          </p>
        </QA>

        <QA question="Comment obtenir une pré-inscription ?">
          <p>
            Il suffit de remplir le formulaire de pré-inscription disponible sur
            la page d’accueil sous la rubrique « Inscrivez-vous en ligne »
          </p>
        </QA>

        <QA question="Existe-t-il une structure d’accueil pour les nouveaux étudiants ?">
          <p>
            A leur demande, les nouveaux étudiants peuvent être accueillis dès
            leur arrivée à l’aéroport Tunis-Carthage.
          </p>

          <p>
            ETUDIANTS INTERNATIONAUX : Notre devise : « Un étranger est un ami
            que l'on n'a pas encore rencontré »
          </p>

          <p>
            Satisfaire votre soif de découverte. Des études à l’étranger : quel
            projet stimulant !
          </p>

          <p>
            Découvrir un nouveau pays et une nouvelle culture : voilà une
            expérience qui vous profitera tout au long de votre carrière et de
            votre vie.
          </p>

          <p>
            L'ouverture sur le monde comporte le soin d'accueillir adéquatement
            son hôte.
          </p>
          <p>
            Ainsi, l'UIK s'emploie à faciliter l'intégration des étudiants
            étrangers à la communauté universitaire et leur adaptation à la
            société tunisienne.
          </p>
          <p>
            Les services ne sont pas seulement utiles dès leur arrivée, mais
            tout au long de leur séjour à l'UIK.
          </p>
          <p>
            L'attention de tout le personnel est portée sur les besoins et les
            demandes des étudiants étrangers.
          </p>
        </QA>

        <QA question="Existe-t-il un service d’aide à la recherche de logement ?">
          <p>
            Un hébergement provisoire est proposé aux nouveaux étudiants par un
            de leurs compatriotes. Les nouveaux arrivants bénéficient également
            d’un service d’aide à la recherche d’un logement individuel ou en
            colocation à proximité de l’un des deux sites de l’UIK.
          </p>
        </QA>

        <QA question="Je rencontre des difficultés pour m’inscrire en ligne, qui contacter?">
          <p> Veuillez contacter Mr. Lotfi Cherif au : </p>

          <p> Tél./WhatsApp +216 97 432 463</p>

          <p> Email : info@uik.ens.tn</p>
        </QA>

        <QA question="Comment obtenir un titre de séjour étudiant ?">
          <p>
            La demande de la carte de séjour doit être effectuée dans un délais
            ne dépassant pas un mois de séjour sur le territoire tunisien ,
            auprès du commissariat le plus proche de votre lieu d’habitation.
          </p>

          <p>
            <strong> Les documents à fournir : </strong>
          </p>
          <p>
            - Le certificat d’inscription à l’université ainsi que le reçu de
            paiement de vos frais de scolarité
          </p>
          <p>- 2 photos d’identité</p>
          <p>
            - 2 timbres fiscaux à acquérir auprès de la Recette des Finances
          </p>
          <p>
            - Un justificatif des ressources en Banque et/ou une attestation de
            prise en charge
          </p>
          <p>- Un justificatif d’assurance maladie/accident</p>
          <p>
            La carte de séjour n’est délivrée qu’après 2 ou 3 mois de la date de
            dépôt de la demande. Elle sera valable une année (jusqu’au 30
            septembre de l’année universitaire en cours) renouvelable chaque
            année. En attendant, le commissariat vous délivre une carte
            provisoire qui vous permet d’être en situation régulière sur tout le
            territoire tunisien.
          </p>
        </QA>

        <QA question="Y’a-t-il des activités extrascolaires au sein de l’université ?">
          <p>
            Tous nos étudiant(e)s sont encouragé(e)s à rejoindre nos
            associations et clubs au début de l’année. Pour plus d’informations,
            veuillez consulter la rubrique{" "}
            <NavLink to="/campus/actualites"> Vie Estudiantine. </NavLink>
          </p>
        </QA>

        <HR1 />
      </div>
    </div>
  );
}

const QA = ({ question, children }) => {
  const [opened, setOpened] = useState(false);

  const handle_change = () => {
    setOpened(!opened);
  };

  return (
    <div className={`${styles.qa} ${opened && styles.opened}`}>
      <div className={styles.question} onClick={handle_change}>
        <span>{question} </span>
        <img src={down_icon} alt="" />
      </div>
      <div className={styles.answer}>{children}</div>
    </div>
  );
};

export default FAQ;
