import React from "react";
import styles from "./styles.module.scss";
import VideoSection from "./sections/VideoSection/VideoSection";
import BlueSecondV2 from "./sections/BlueSecondV2/BlueSecondV2";
import Actuality from "./sections/Actuality/Actuality";
import MeetTheStudent from "./sections/MeetTheStudents/MeetTheStudent";
import MeetTheFaculty from "./sections/MeetTheFaculty/MeetTheFaculty";
import NosAluminies from "./sections/NosAlumnies/NosAluminies";
import VieAuCompus from "./sections/VieAuCompus/VieAuCompus";
import ContactSection from "../../layouts/ContactSecion/ContactSection";
import Footer from "../../layouts/Footer/Footer";
import MainNavbar from "../../layouts/Navbars/main_navbar/MainNavbar";
import SecondNavbar from "../../layouts/Navbars/second_navbar/SecondNavbar";
import Faculties from "./sections/Faculties/Faculties";

function Home() {
  return (
    <div className={styles["main"]}>
      <MainNavbar />
      <SecondNavbar />
      <VideoSection />
      <BlueSecondV2 />
      <Faculties />
      <Actuality />
      <MeetTheStudent />
      <MeetTheFaculty />
      <NosAluminies />
      <VieAuCompus />
      <ContactSection />
      <Footer />
    </div>
  );
}

export default Home;
