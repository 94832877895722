import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
// import { images } from "./fake_data";

import filter1 from "../../../../assets/images/compus/filter1.png";
import filter2 from "../../../../assets/images/compus/filter2.png";
import elipse from "../../../../assets/images/compus/elipse.png";
import image from "../../../../assets/images/compus/image.png";

import { ZoomIn } from "../../../../components/Animations/Zoom";
import { FadeUp } from "../../../../components/Animations/Fade";

import quote2 from "../../../../assets/svgs/icons/quote2.png";

import { FullImage } from "../../../CAMPUS/SubPages/Mediatheque/Mediatheque";
import customAxios from "../../../../utils/axios";

function VieAuCompus() {
  const [selected_image, setselected_image] = useState(null);

  const [index, setIndex] = useState(null);
  const [images, setImages] = useState([]);

  const close = () => {
    setIndex(null);
  };

  useEffect(() => {
    customAxios.get("/Media/getAll").then((res) => {
      console.log(res.data.Media);
      setImages(
        res?.data?.Media?.slice(0, 12).map(
          (media) => process.env.REACT_APP_API_BACK + media.image
        )
      );
    });
  }, []);

  useEffect(() => {
    if (selected_image) {
      setIndex(images.indexOf(selected_image));
    }
  }, [selected_image]);

  return (
    <div className={styles["main"]}>
      {index !== null && (
        <FullImage
          index={index}
          close={close}
          images={images.map((img) => ({ img: img, text: "" }))}
        />
      )}
      <RealImages images={images} />
      <BigCircle />
      <FakeSelection images={images} setselected_image={setselected_image} />
    </div>
  );
}

function RealImages({ images }) {
  return (
    <div className={styles["images"]}>
      {images.map((image, index) => {
        return <Image key={index} image={image} />;
      })}
    </div>
  );
}

function FakeSelection({ setselected_image, images }) {
  return (
    <div className={styles["fake-images"]}>
      {images.map((image, index) => {
        return (
          <FakeImage
            key={index}
            image={image}
            onClick={() => {
              setselected_image(image);
            }}
          />
        );
      })}
    </div>
  );
}

function BigCircle({ selected_image }) {
  return (
    <FadeUp className={styles["content"]}>
      <div className={styles["circle"]}>
        <img className={styles["elipse"]} src={elipse} alt="" />
        <div className={styles["sub-content"]}>
          {
            <img
              src={selected_image ? selected_image : image}
              className={selected_image ? styles["selected"] : ""}
              alt=""
            />
          }
        </div>
        {!selected_image && (
          <div className={styles["sub-content2"]}>
            <div className={styles["container"]}>
              <img src={quote2} alt="" className={styles["left"]} />
              <h2>
                Vie sur <br /> le campus
              </h2>
              <img src={quote2} alt="" className={styles["right"]} />
            </div>
          </div>
        )}
      </div>
    </FadeUp>
  );
}

function Image({ image }) {
  return (
    <ZoomIn className={styles["image"]}>
      <img src={image} alt="" className={styles["real-img"]} />
      <img src={filter1} alt="" className={styles["filter1"]} />
      <img src={filter2} alt="" className={styles["filter2"]} />
    </ZoomIn>
  );
}

function FakeImage({ image, onClick }) {
  return <div className={styles["fake-image"]} onClick={onClick}></div>;
}

export default VieAuCompus;
