import React from "react";
import styles from "./styles.module.scss";
import { FaArrowRight } from "react-icons/fa";

function LeftArrowedTitle({ children }) {
  return (
    <div className={styles.main2}>
      <FaArrowRight className={styles.arrow} />
      <h2>{children} </h2>
    </div>
  );
}

export default LeftArrowedTitle;
