import React from "react";
import styles from "./styles.module.scss";

function TitleUndLine({ children, color = "blue", className = "" }) {
  return (
    <div className={`${styles["actuality"]} ${styles[color]} ${className}`}>
      <h2>{children}</h2>
      <hr />
    </div>
  );
}

export default TitleUndLine;
