import React from "react";
import styles from "./styles.module.scss";
import RightColoredTitle from "../../../../components/RightColoredTitle/RightColoredTitle";
import { InscrireBtn2 } from "../../../../components/buttons/Buttons";
import ImgBack from "../../../../components/Image/ImgBack";
import { ZoomIn } from "../../../../components/Animations/Zoom";

function Books({ data = [], logo = "" }) {
  const GoToBookUrl = (url) => {
    window.open(url, "_blank");
  };

  return (
    <div className={styles["main"]}>
      <div className={styles["header"]}>
        <RightColoredTitle fontSize={40}>
          {"Books"}
          {/* {"Books des travaux des etudiants"} */}
        </RightColoredTitle>
        <ImgBack src={logo} alt="" className={styles["logo"]} />
      </div>

      <div className={styles["body"]}>
        <div className={styles["books"]}>
          {data.map((book, key) => {
            return (
              <ZoomIn
                key={key}
                className={styles["book"]}
                onClick={() => {
                  GoToBookUrl(book.link);
                }}
              >
                <ImgBack src={book.img} alt="book" />
                <h3>{book.title}</h3>
              </ZoomIn>
            );
          })}
        </div>

        <div className={styles["button"]}>
          <InscrireBtn2>S'inscrire maintenant !</InscrireBtn2>
        </div>
      </div>
    </div>
  );
}

export default Books;
