import { useMediaQuery } from "react-responsive";

function useResolution() {
  const xs = useMediaQuery({
    query: "(max-width: 599px)",
  });

  const sm = useMediaQuery({
    query: "(max-width: 959px)",
  });

  const md = useMediaQuery({
    query: "(max-width: 1279px)",
  });

  const lg = useMediaQuery({
    query: "(max-width: 1370px)",
  });

  return { xs, sm, md, lg };
}

export default useResolution;
