import React from "react";
import styles from "./styles.module.scss";

function RightColoredTitle({ children, fontSize = 35, className = "" }) {
  return (
    <h1
      className={`${styles.main} ${className}`}
      style={{ fontSize: `${fontSize}px` }}
    >
      {children}
    </h1>
  );
}

export default RightColoredTitle;
