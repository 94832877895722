import React from "react";
import styles from "./styles.module.scss";
import { ZoomIn } from "../../Animations/Zoom";
import { useNavigate, useParams } from "react-router-dom";

function Degree({ degree }) {
  const { title, description, _id } = degree;

  const navigate = useNavigate();
  const { univ, type } = useParams();

  const setDegree = () => {
    navigate(`/university/${univ}/${type}/${_id}`);
  };

  return (
    <ZoomIn className={styles["main"]}>
      <h2>{title}</h2>
      <p>{description}</p>
      <button onClick={setDegree}>UIK</button>
    </ZoomIn>
  );
}

export default Degree;
