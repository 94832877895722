export const pays = [
  "AFGHANISTAN",
  "AFRIQUE DU SUD",
  "ALBANIE",
  "ALGERIE",
  "ALLEMAGNE",
  "ANDORRE",
  "ANGOLA",
  "ANTARCTIQUE",
  "ANTIGUA",
  "ANTILLES NEERLANDAISES",
  "ARABIE SEOUDITE",
  "ARGENTINE",
  "ARMENIE",
  "AUSTRALIE",
  "AUTRICHE",
  "AZERBAIJAN",
  "BAHREIN",
  "BANGLADESH",
  "BELGIQUE",
  "BELIZE",
  "BENIN",
  "BERMUDES",
  "BHOUTAN",
  "BIOLORUSSIE",
  "BIRMANIE",
  "BOLIVIE",
  "BOSNIE HERZEGOVINE",
  "BOTSWANA",
  "BRESIL",
  "BRUNEI",
  "BULGARIE",
  "BURUNDI",
  "CAMEROUN",
  "CANADA",
  "CEYLAN",
  "CHILI",
  "CHINE CONTINENTALE",
  "CHYPRE",
  "COLOMBIE",
  "COMBODGE",
  "CONGO",
  "COREE DU NORD",
  "COREE DU SUD",
  "COSTA RICA",
  "COTE D IVOIRE",
  "CROATIE",
  "CUBA",
  "DANEMARK",
  "DIVERSE ILE DU PACIFIQUE (USA)",
  "DJIBOUTI",
  "DOMINICA",
  "EGYPTE",
  "EQUATEUR",
  "ERYTHREE",
  "ESPAGNE",
  "ESTONIE",
  "ETATS DES EMIRATS ARABES UNIS",
  "ETATS UNIS D'AMERIQUE",
  "ETHIOPIE",
  "FIDJI",
  "FINLANDE",
  "FRANCE",
  "GABON",
  "GEORGIE",
  "GHANA",
  "GIBRALTAR",
  "GRANDE BRETAGNE",
  "GRECE",
  "GRENADE",
  "GROENLAND",
  "GUADELOUPE",
  "GUAM",
  "GUATEMALA",
  "GUINEE",
  "GUINEE EQUATORIALE",
  "GUINEE-BISSAU",
  "GUYANE",
  "GUYANE FRANCAISE",
  "HAITI",
  "HAUTE VOLTA",
  "HONDURAS",
  "HONG KONG",
  "HONGRIE",
  "ILE DE BOUVET",
  "ILE DE CHRISTMAS",
  "ILE DE COOK",
  "ILE DE JOHNSTON",
  "ILE DE NORFOLK",
  "ILE DE PITCAIRN",
  "ILE DE WAKE",
  "ILE MAURICE",
  "ILES BAHAMAS",
  "ILES BARBADOS",
  "ILES CANARIES",
  "ILES COMORES",
  "ILES DE CAIMANES (GB)",
  "ILES DE CANTON ET ENDERBURY",
  "ILES DE FALKLAND",
  "ILES DE FEROE",
  "ILES DE MIDWAY",
  "ILES DE SALOMON",
  "ILES DE TURKS ET CAIQUES",
  "ILES DE VIERGES AMERICAINES",
  "ILES DE VIERGES BRITANNIQUES",
  "ILES DE WALIS ET FUTUNA",
  "ILES DES COCOS",
  "ILES DU CAP-VERT",
  "ILES DU PACIFIQUE",
  "ILES HEARD ET MC DONALD",
  "INDE",
  "INDONESIE",
  "IRAK",
  "IRAN",
  "IRLANDE",
  "ISLANDE",
  "ITALIE",
  "JAMAIQUE",
  "JAPON",
  "JORDANIE",
  "KAZAKHISTAN",
  "KENYA",
  "KIRGHIZISTAN",
  "KIRIBATI",
  "KOWEIT",
  "LAOS",
  "LESOTHO",
  "LETTONIE",
  "LIBAN",
  "LIBERIA",
  "LIECHTENSTEIN",
  "LITHUANIE",
  "LUXEMBOURG",
  "LYBIE",
  "MACAO",
  "MALAISIE",
  "MALAWIE",
  "MALDIVES",
  "MALI",
  "MALTE",
  "MAROC",
  "MARTINIQUE",
  "MAURITANIE",
  "MEXIQUE",
  "MOLDAVIE",
  "MONACO",
  "MONGOLIE",
  "MONTSERRAT",
  "MOZAMBIQUE",
  "NAMIBIE",
  "NAURU",
  "NEPAL",
  "NICARAGUA",
  "NIGER",
  "NIGERIA",
  "NIOUE",
  "NORVEGE",
  "NOUVELLE ZELANDE",
  "NOUVELLE-CALEDONIE",
  "OMAN",
  "OUGANDA",
  "OUZBEKISTAN",
  "PAKISTAN",
  "PALESTINE",
  "PANAMA",
  "PAPOUASIE NOUVELLE-GUINEE",
  "PARAGUAY",
  "PAYS BAS",
  "PEROU",
  "PHILIPPINE",
  "POLOGNE",
  "POLYNESIE FRANCAISE",
  "PORTO RICO",
  "PORTUGAL",
  "QATAR",
  "REP. DEMOCRATIQUE D'ALLEMAGNE",
  "REP. FEDERALE D'ALLEMAGNE",
  "REPRISE DE DONNEES",
  "REPUBLIQUE CENTRE AFRICAINE",
  "REPUBLIQUE DE GAMBIE",
  "REPUBLIQUE DOMINICAINE",
  "REPUBLIQUE GABONAISE",
  "REPUBLIQUE MALGACHE",
  "REPUBLIQUE TCHEQUE",
  "REUNION",
  "ROUMANIE",
  "RUSSIE",
  "RWANDA",
  "SAHARA OCCIDENTAL",
  "SAINT-LUCIE",
  "SAINT-MARIN",
  "SAINT-PIERRE-ET-MIQUELON",
  "SAINT-VINCENT-ET-GRENADINES",
  "SAINTE-HELENE",
  "SALVADOR",
  "SAMOA",
  "SAMOA AMERICAINES",
  "SAO TOME-ET-PRINCIPE",
  "SENEGAL",
  "SEYCHELLES",
  "SIERRA LEONE",
  "SINGAPOUR",
  "SLOVAQUIE",
  "SLOVENIE",
  "SOMALIE",
  "SOUDAN",
  "ST-CHRISTOPHE NIEVES ANGUILLA",
  "SUEDE",
  "SUISSE",
  "SURINAME",
  "SVALBARD ET ILE JAN MAYEN",
  "SWAZILAND",
  "SYRIE",
  "TADJIKISTAN",
  "TAIWAN",
  "TANZANIE",
  "TCHAD",
  "TCHECOSLOVAQUIE",
  "TERRE DE LA REINE MAUD",
  "THAILAND",
  "TIMOR ORIENTAL",
  "TOGO",
  "TOKELAOU",
  "TONGA",
  "TRINITE-ET-TOBAGO",
  "TUNISIE",
  "TURKMENISTAN",
  "TURQUIE",
  "TUVALU",
  "U.R.S.S.",
  "UKRAINE",
  "URUGUAY",
  "VANUATU",
  "VATICAN",
  "VENEZUELA",
  "VIETNAM",
  "YEMEN",
  "YEMEN DU NORD",
  "YEMEN DU SUD",
  "YOUGOSLAVIE",
  "ZAIRE",
  "ZAMBIE",
  "ZIMBABWE",
  "ZONE NEUTRE",
];

export const nationals = [
  "AFGHANISTAN",
  "AFRIQUE DU SUD",
  "ALBANIE",
  "ALGERIE",
  "ALLEMAGNE",
  "ANDORRE",
  "ANGOLA",
  "ANTARCTIQUE",
  "ANTIGUA",
  "ANTILLES NEERLANDAISES",
  "ARABIE SEOUDITE",
  "ARGENTINE",
  "ARMENIE",
  "AUSTRALIE",
  "AUTRICHE",
  "AZERBAIJAN",
  "BAHREIN",
  "BANGLADESH",
  "BELGIQUE",
  "BELIZE",
  "BENIN",
  "BERMUDES",
  "BHOUTAN",
  "BIOLORUSSIE",
  "BIRMANIE",
  "BOLIVIE",
  "BOSNIE HERZEGOVINE",
  "BOTSWANA",
  "BRESIL",
  "BRUNEI",
  "BULGARIE",
  "BURUNDI",
  "CAMEROUN",
  "CANADA",
  "CEYLAN",
  "CHILI",
  "CHINE CONTINENTALE",
  "CHYPRE",
  "COLOMBIE",
  "COMBODGE",
  "CONGO",
  "COREE DU NORD",
  "COREE DU SUD",
  "COSTA RICA",
  "COTE D IVOIRE",
  "CROATIE",
  "CUBA",
  "DANEMARK",
  "DIVERSE ILE DU PACIFIQUE (USA)",
  "DJIBOUTI",
  "DOMINICA",
  "EGYPTE",
  "EQUATEUR",
  "ERYTHREE",
  "ESPAGNE",
  "ESTONIE",
  "ETATS DES EMIRATS ARABES UNIS",
  "ETATS UNIS D'AMERIQUE",
  "ETHIOPIE",
  "FIDJI",
  "FINLANDE",
  "FRANCE",
  "GABON",
  "GEORGIE",
  "GHANA",
  "GIBRALTAR",
  "GRANDE BRETAGNE",
  "GRECE",
  "GRENADE",
  "GROENLAND",
  "GUADELOUPE",
  "GUAM",
  "GUATEMALA",
  "GUINEE",
  "GUINEE EQUATORIALE",
  "GUINEE-BISSAU",
  "GUYANE",
  "GUYANE FRANCAISE",
  "HAITI",
  "HAUTE VOLTA",
  "HONDURAS",
  "HONG KONG",
  "HONGRIE",
  "ILE DE BOUVET",
  "ILE DE CHRISTMAS",
  "ILE DE COOK",
  "ILE DE JOHNSTON",
  "ILE DE NORFOLK",
  "ILE DE PITCAIRN",
  "ILE DE WAKE",
  "ILE MAURICE",
  "ILES BAHAMAS",
  "ILES BARBADOS",
  "ILES CANARIES",
  "ILES COMORES",
  "ILES DE CAIMANES (GB)",
  "ILES DE CANTON ET ENDERBURY",
  "ILES DE FALKLAND",
  "ILES DE FEROE",
  "ILES DE MIDWAY",
  "ILES DE SALOMON",
  "ILES DE TURKS ET CAIQUES",
  "ILES DE VIERGES AMERICAINES",
  "ILES DE VIERGES BRITANNIQUES",
  "ILES DE WALIS ET FUTUNA",
  "ILES DES COCOS",
  "ILES DU CAP-VERT",
  "ILES DU PACIFIQUE",
  "ILES HEARD ET MC DONALD",
  "INDE",
  "INDONESIE",
  "IRAK",
  "IRAN",
  "IRLANDE",
  "ISLANDE",
  "ITALIE",
  "JAMAIQUE",
  "JAPON",
  "JORDANIE",
  "KAZAKHISTAN",
  "KENYA",
  "KIRGHIZISTAN",
  "KIRIBATI",
  "KOWEIT",
  "LAOS",
  "LESOTHO",
  "LETTONIE",
  "LIBAN",
  "LIBERIA",
  "LIECHTENSTEIN",
  "LITHUANIE",
  "LUXEMBOURG",
  "LYBIE",
  "MACAO",
  "MALAISIE",
  "MALAWIE",
  "MALDIVES",
  "MALI",
  "MALTE",
  "MAROC",
  "MARTINIQUE",
  "MAURITANIE",
  "MEXIQUE",
  "MOLDAVIE",
  "MONACO",
  "MONGOLIE",
  "MONTSERRAT",
  "MOZAMBIQUE",
  "NAMIBIE",
  "NAURU",
  "NEPAL",
  "NICARAGUA",
  "NIGER",
  "NIGERIA",
  "NIOUE",
  "NORVEGE",
  "NOUVELLE ZELANDE",
  "NOUVELLE-CALEDONIE",
  "OMAN",
  "OUGANDA",
  "OUZBEKISTAN",
  "PAKISTAN",
  "PALESTINE",
  "PANAMA",
  "PAPOUASIE NOUVELLE-GUINEE",
  "PARAGUAY",
  "PAYS BAS",
  "PEROU",
  "PHILIPPINE",
  "POLOGNE",
  "POLYNESIE FRANCAISE",
  "PORTO RICO",
  "PORTUGAL",
  "QATAR",
  "REP. DEMOCRATIQUE D'ALLEMAGNE",
  "REP. FEDERALE D'ALLEMAGNE",
  "REPRISE DE DONNEES",
  "REPUBLIQUE CENTRE AFRICAINE",
  "REPUBLIQUE DE GAMBIE",
  "REPUBLIQUE DOMINICAINE",
  "REPUBLIQUE GABONAISE",
  "REPUBLIQUE MALGACHE",
  "REPUBLIQUE TCHEQUE",
  "REUNION",
  "ROUMANIE",
  "RUSSIE",
  "RWANDA",
  "SAHARA OCCIDENTAL",
  "SAINT-LUCIE",
  "SAINT-MARIN",
  "SAINT-PIERRE-ET-MIQUELON",
  "SAINT-VINCENT-ET-GRENADINES",
  "SAINTE-HELENE",
  "SALVADOR",
  "SAMOA",
  "SAMOA AMERICAINES",
  "SAO TOME-ET-PRINCIPE",
  "SENEGAL",
  "SEYCHELLES",
  "SIERRA LEONE",
  "SINGAPOUR",
  "SLOVAQUIE",
  "SLOVENIE",
  "SOMALIE",
  "SOUDAN",
  "ST-CHRISTOPHE NIEVES ANGUILLA",
  "SUEDE",
  "SUISSE",
  "SURINAME",
  "SVALBARD ET ILE JAN MAYEN",
  "SWAZILAND",
  "SYRIE",
  "TADJIKISTAN",
  "TAIWAN",
  "TANZANIE",
  "TCHAD",
  "TCHECOSLOVAQUIE",
  "TERRE DE LA REINE MAUD",
  "THAILAND",
  "TIMOR ORIENTAL",
  "TOGO",
  "TOKELAOU",
  "TONGA",
  "TRINITE-ET-TOBAGO",
  "TUNISIE",
  "TURKMENISTAN",
  "TURQUIE",
  "TUVALU",
  "U.R.S.S.",
  "UKRAINE",
  "URUGUAY",
  "VANUATU",
  "VATICAN",
  "VENEZUELA",
  "VIETNAM",
  "YEMEN",
  "YEMEN DU NORD",
  "YEMEN DU SUD",
  "YOUGOSLAVIE",
  "ZAIRE",
  "ZAMBIE",
  "ZIMBABWE",
  "ZONE NEUTRE",
];
