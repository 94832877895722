import keys from "./colors.keys";

export const InitialState = {
  theme: "theme-blue",
};

export const ColorReducer = (state = { ...InitialState }, action) => {
  switch (action.type) {
    case keys.change_theme:
      return { ...state, theme: action.value };
    default:
      return state;
  }
};
