export const init_data = {
  Nom: "",
  Prenom: "",
  // NOM: "bairem",
  TELEPHONE: "",
  EMAIL: "",
  obj: "",
  msg: "",
};

export const validation = (form) => {
  return true;
};
