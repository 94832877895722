import React, { useState } from "react";
import styles from "./styles.module.scss";
import LeftColoredTitle from "../../../../components/Titles/LeftColoredTitle";
import { InscrireBtn } from "../../../../components/buttons/Buttons";
import { GrClose } from "react-icons/gr";
import download from "downloadjs";
import { PreInscription } from "../../../../services/inscription";
import { toast } from "react-hot-toast";

const init_data = {
  NOM: "",
  TELEPHONE: "",
  EMAIL: "",
};

const validation = (form = init_data) => {
  if (!form.NOM) {
    toast.error("Nom est obligatoire");
    return false;
  }

  if (!form.TELEPHONE) {
    toast.error("Telephone est obligatoire");
    return false;
  }

  if (!form.EMAIL) {
    toast.error("Email est obligatoire");
    return false;
  }

  return true;
};

function PopupInsc({ close, pdfLink }) {
  const [form, setForm] = useState({ ...init_data });
  const [loading, setLoading] = useState(false);

  const Handle_Download = () => {
    download(pdfLink);
  };

  const handle_change = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handle_submit = () => {
    if (validation(form)) {
      setLoading(true);
      PreInscription(form)
        .then((resp) => {
          setForm({ ...init_data });
          // toast.success("Inscription effectuée avec succès");
          setLoading(false);
          Handle_Download();
          close();
        })
        .catch((error) => {
          setLoading(false);
          toast.success("Server error");
        });
    }
  };

  return (
    <div className={styles.main}>
      <div className={styles.body}>
        <div className={styles.close}>
          <GrClose className={styles.close_icon} onClick={close} />
        </div>

        <LeftColoredTitle>Informations générales</LeftColoredTitle>
        <div className={styles.form}>
          <div className={styles.w100}>
            <label> Nom & Prénom * </label>
            <input onChange={handle_change} value={form.NOM} name="NOM" />
          </div>

          <div className={styles.w100}>
            <label> Telephone * </label>
            <input
              onChange={handle_change}
              value={form.TELEPHONE}
              name="TELEPHONE"
            />
          </div>

          <div className={styles.w100}>
            <label> Email * </label>
            <input onChange={handle_change} value={form.EMAIL} name="EMAIL" />
          </div>
        </div>

        <div className={styles.send}>
          <InscrireBtn
            onClick={handle_submit}
            loading={loading}
            txt_loading="Chargement..."
          >
            Telecharger
          </InscrireBtn>
        </div>
      </div>
    </div>
  );
}

export default PopupInsc;
