import React from "react";
import styles from "./styles.module.scss";
import LeftColoredTitle from "../../../../components/Titles/LeftColoredTitle";

import vie_img from "../../../../assets/images/campus/vie_est.png";
import { HR1 } from "../../../../components/HRs/HRs";

function StudentLife() {
  return (
    <div className={styles.main}>
      <LeftColoredTitle>Vie estudiantine</LeftColoredTitle>
      {/* <HR1 /> */}
      {/* <HR1 /> */}
      <div className={styles.container2}>
        <br />
        <img src={vie_img} alt="UIK Campus" className={styles.fac_img} />

        <h2 className={styles.sub_title}>Présentation :</h2>
        <p className={`${styles.desc} ${styles.parag}`}>
          Si vous recherchez une expérience étudiante dynamique et
          épanouissante, l'Université Ibn Khaldoun (UIK) est l'endroit idéal
          pour vous. Niché dans un campus spacieux et verdoyant de 15 000 m2
          accessible 24h/24, l'UIK offre une multitude d'activités pour les
          étudiants, allant des clubs et des organisations étudiantes aux
          événements sportifs et culturels. De plus, le campus est réputé pour
          ses événements annuels tels que le UIK Career Day, UIK Got Talents,
          ainsi que la Mini-Can et le UIK Startup Day.
        </p>

        <p className={`${styles.desc} ${styles.parag}`}>
          Le campus de l'UIK est un lieu magnifique et animé, offrant bien plus
          que des salles de cours. Doté d'installations modernes, telles qu'un
          complexe sportif omnisports et une piscine, il offre également de
          vastes espaces verts et des jardins qui permettent aux étudiants de se
          détendre et de se ressourcer entre les cours. Venez découvrir la vie
          étudiante sur le campus de l'UIK et vous serez enchanté par les
          nombreuses activités qui vous y attendent !
        </p>

        <h2 className={styles.sub_title}>Comment fonder un club :</h2>

        <p className={`${styles.desc} ${styles.parag}`}>
          Si vous ne trouvez pas un club qui vous convient, vous pouvez fonder
          votre propre club. Pour cela, il vous faudra réunir 5 étudiants en
          tout et remplir le formulaire de création de club cidessous. Le
          formulaire doit être rempli dans un délai qui ne dépasse pas les 6
          premières semaines du semestre. En cas d’approbation, une salle sera
          réservée pour vos réunions hebdomadaires en fonction de la
          disponibilité et un budget semestriel de 250 dinars sera attribué pour
          vos actions et événements.
        </p>

        <p className={`${styles.desc} ${styles.parag}`}>
          Notez que toute dépense doit être approuvée d’avance et accompagné de
          factures en bonne et due forme. Notez aussi que vous pouvez toujours
          demander un financement supplémentaire une fois le budget semestriel
          épuisé. Si un club a été actif par le présent et ne l’est plus
          aujourd’hui, vous pouvez aussi le réactiver en suivant la même
          procédure que celle de la création de club.
        </p>

        <h2 className={styles.sub_title}>Le BDE : </h2>

        <p className={`${styles.desc} ${styles.parag}`}>
          Le Bureau des Étudiants (BDE) de l'Université Ibn Khaldoun (UIK) est
          bien plus qu'une simple association étudiante. En plus d'organiser des
          activités extra-scolaires, il remplit également un rôle essentiel en
          tant que représentant démocratique des étudiants auprès de
          l'administration de l'université.
        </p>

        <p className={`${styles.desc} ${styles.parag}`}>
          En tant que représentant des étudiants, le BDE porte les voix et les
          aspirations de la communauté étudiante. Il joue un rôle actif dans la
          prise de décisions et l'élaboration de politiques qui affectent
          directement la vie étudiante. Les membres du BDE sont élus par leurs
          pairs, ce qui garantit une représentation démocratique et légitime des
          étudiants.
        </p>

        <p className={`${styles.desc} ${styles.parag}`}>
          Le BDE de l'UIK est responsable de la planification et de la
          réalisation d'un large éventail d'événements. Il organise
          régulièrement des soirées étudiantes, des activités culturelles, des
          rencontres sportives et bien d'autres initiatives qui enrichissent la
          vie étudiante sur le campus. Ces événements visent à promouvoir
          l'engagement, le divertissement et la cohésion entre les étudiants.
        </p>
      </div>
    </div>
  );
}

export default StudentLife;
