import { useEffect } from "react";
import { useState } from "react";

function useAllTrue(states = []) {
  const [state, setState] = useState();

  useEffect(() => {
    let res = true;
    states.forEach((st) => {
      if (st === false) {
        res = false;
      }
    });
    setState(res);
  }, [states]);

  return state;
}

export default useAllTrue;
