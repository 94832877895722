import React from "react";
import styles from "./styles.module.scss";
import LeftColoredTitle from "../../../../components/Titles/LeftColoredTitle";
import EventCard from "../../../../components/EventCard/EventCard";

// import { Swippers } from "../../../../components/Carossel/Carossel";
import { HR1 } from "../../../../components/HRs/HRs";
import { events } from "../Actuality/events";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";
import { DateDoted } from "../../../../functions/Data";
import { FullImage } from "../Mediatheque/Mediatheque";

import { ZoomIn } from "../../../../components/Animations/Zoom";
import customAxios from "../../../../utils/axios";
import ImgBack from "../../../../components/Image/ImgBack";

function OneEvent() {
  const params = useParams();
  const navig = useNavigate();
  const [evets, setEvets] = useState([]);
  const [event, setEvent] = useState({
    title: "",
    description: "",
    main_img: "",
    images: [""],
    date: "01-01-2023",
    in_home: 0,
  });

  useEffect(() => {
    customAxios.get("/Event/getAll").then((res) => {
      setEvets(res?.data?.Actuality || []);
    });
  }, []);

  const [index, setIndex] = useState(null);

  const close = () => {
    setIndex(null);
  };

  useEffect(() => {
    let id = params.event;

    customAxios
      .get(`/Event/getById/${id}`)
      .then((res) => {
        setEvent(res.data);
      })
      .catch((err) => {
        navig("/campus/actualites");
      });
  }, [params]);

  const GoToEvent = (id) => {
    navig(`/campus/actualites/${id}`);
  };

  return (
    <div className={styles.main}>
      <LeftColoredTitle>{event.title}</LeftColoredTitle>
      <p className={styles.date}> {DateDoted(event.date)} </p>

      <div className={styles.container2}>
        <ImgBack className={styles.main_img} src={event.main_img} alt="" />

        <p className={styles.parag}>{event.description}</p>

        <div className={styles.images}>
          {event.images.map((img, key) => {
            return (
              <ZoomIn>
                <ImgBack
                  src={img}
                  key={key}
                  alt={event.title}
                  onClick={() => {
                    setIndex(key);
                  }}
                />
              </ZoomIn>
            );
          })}
        </div>

        <br />
        <br />
        <br />

        <LeftColoredTitle>Related Events</LeftColoredTitle>

        <div className={styles.events}>
          {evets
            .filter((evt) => evt._id !== params.event)
            .filter((_, key) => key < 2)
            .map((event, key) => {
              return (
                <ZoomIn
                  key={key}
                  className={styles.event_container}
                  onClick={() => {
                    GoToEvent(event._id);
                  }}
                >
                  <EventCard
                    onClick={GoToEvent}
                    // date="08 . 04 . 2023"
                    date={DateDoted(event.date)}
                    img={event.main_img}
                    title={event.title}
                  />
                </ZoomIn>
              );
            })}
        </div>

        {/* <Swippers /> */}
        <HR1 />

        {index !== null && (
          <FullImage
            index={index}
            close={close}
            images={event.images.map((img) => {
              return process.env.REACT_APP_API_BACK + img;
            })}
          />
        )}
      </div>
    </div>
  );
}

export default OneEvent;
