const keys = {
  NAVBARS_OPEN1: "NAVBARS_OPEN1",
  NAVBARS_OPEN2: "NAVBARS_OPEN2",
  NAVBARS_CLOSE: "NAVBARS_CLOSE",
};

export const InitialState = {
  open_main: false,
  open_second: false,
};

export const NavbarReducer = (state = { ...InitialState }, action) => {
  switch (action.type) {
    case keys.NAVBARS_OPEN1:
      return { ...state, ...action.value };
    case keys.NAVBARS_OPEN2:
      return { ...state, ...action.value };
    case keys.NAVBARS_CLOSE:
      return { ...state, ...action.value };
    default:
      return state;
  }
};

// ###################################### Actions ###################################### //

export const OpenNavbarMainAction = () => {
  return async (dispatch) => {
    dispatch({
      type: keys.NAVBARS_OPEN1,
      value: { open_main: true, open_second: false },
    });
  };
};

export const OpenNavbarSecondAction = () => {
  return async (dispatch) => {
    dispatch({
      type: keys.NAVBARS_OPEN2,
      value: { open_main: false, open_second: true },
    });
  };
};

export const CloseNavbarsAction = () => {
  return async (dispatch) => {
    dispatch({
      type: keys.NAVBARS_CLOSE,
      value: { open_main: false, open_second: false },
    });
  };
};
