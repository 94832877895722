import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import LeftColoredTitle from "../../../../components/Titles/LeftColoredTitle";

import arrow1 from "../../../../assets/svgs/icons/media_arrow1.svg";
import arrow2 from "../../../../assets/svgs/icons/media_arrow2.svg";

import { Swippers } from "../../../../components/Carossel/Carossel";
import { HR1 } from "../../../../components/HRs/HRs";

import { data } from "./fake_data";

import { ZoomIn } from "../../../../components/Animations/Zoom";
import { createArray } from "../../../../functions/Arrays";
import customAxios from "../../../../utils/axios";

function Mediatheque() {
  const [index, setIndex] = useState(null);
  const [images, setImages] = useState([]);

  useEffect(() => {
    customAxios.get("/Media/getAll").then((res) => {
      console.log(res.data.Media);
      setImages(
        res?.data?.Media?.map(
          (media) => process.env.REACT_APP_API_BACK + media.image
        )
      );
    });
  }, []);

  const close = () => {
    setIndex(null);
  };

  return (
    <div className={styles.main}>
      <LeftColoredTitle>Médiathèque</LeftColoredTitle>
      {index !== null && (
        <FullImage index={index} close={close} images={images} />
      )}

      <div className={styles.container2}>
        <div className={styles.medias}>
          {images.map((item, key) => {
            return (
              <ZoomIn key={key} className={styles.media}>
                <img
                  src={item}
                  alt=""
                  onClick={() => {
                    setIndex(key);
                  }}
                />
              </ZoomIn>
            );
          })}
        </div>

        <Swippers />
        <HR1 />
      </div>
    </div>
  );
}

export const FullImage = ({ index, close, images }) => {
  const [real_index, setreal_index] = useState(index);

  useEffect(() => {
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  useEffect(() => {
    setreal_index(index);
  }, [index]);

  const handle_prev = () => {
    if (real_index > 0) {
      setreal_index(real_index - 1);
    }
  };

  const handle_next = () => {
    if (real_index < images.length - 1) {
      setreal_index(real_index + 1);
    }
  };

  return (
    <div className={styles.full_img}>
      <div className={styles.layer1}>
        <img src={arrow1} alt="" className={styles.back} onClick={close} />

        <div className={styles.images}>
          <img
            src={arrow2}
            alt=""
            className={styles.left}
            onClick={handle_prev}
          />
          <img src={images[real_index]} alt="" className={styles.main_img} />
          <img
            src={arrow2}
            alt=""
            className={styles.right}
            onClick={handle_next}
          />
          <div className={styles.puces}>
            {createArray(images.length).map((item, key) => {
              return (
                <span
                  className={key === real_index ? styles.active : styles.puce}
                  key={key}
                />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Mediatheque;
