import React from "react";
import styles from "./styles.module.scss";
import see_more_white from "../../assets/svgs/icons/see_more_white.svg";
import see_more_blue from "../../assets/svgs/icons/see_more_blue.svg";
import see_more_blue2 from "../../assets/svgs/icons/see_more_blue2.svg";
import see_more_grey from "../../assets/svgs/icons/see_more_grey.svg";

function SeeMore({
  children,
  link = "",
  blue_icon = false,
  blue_icon2 = false,
  white_icon = false,
  grey_icon = false,
  blue_text1 = false,
  blue_text2 = false,
  blue_text3 = false,
  white_text = false,
  grey_text = false,
  onClick = () => {},
}) {
  return (
    <div className={styles["main"]} onClick={onClick}>
      {grey_icon && <img src={see_more_grey} alt="" />}
      {white_icon && <img src={see_more_white} alt="" />}
      {blue_icon && <img src={see_more_blue} alt="" />}
      {blue_icon2 && <img src={see_more_blue2} alt="" />}
      {blue_text1 && <span className={styles["blue1"]}>{children}</span>}
      {blue_text2 && <span className={styles["blue2"]}>{children}</span>}
      {blue_text3 && <span className={styles["blue3"]}>{children}</span>}
      {white_text && <span className={styles["white"]}>{children}</span>}
      {grey_text && <span className={styles["grey"]}>{children}</span>}
    </div>
  );
}

export default SeeMore;
