import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import LeftColoredTitle from "../../../../components/Titles/LeftColoredTitle";
import EventCard from "../../../../components/EventCard/EventCard";

import { Swippers } from "../../../../components/Carossel/Carossel";
import { HR1 } from "../../../../components/HRs/HRs";
import { useNavigate } from "react-router-dom";

import { events } from "./events";
import { divideArrayIntoSubarrays } from "../../../../functions/Arrays";
import { DateDoted } from "../../../../functions/Data";

import { ZoomIn } from "../../../../components/Animations/Zoom";
import customAxios from "../../../../utils/axios";

function Actuality() {
  const navig = useNavigate();
  const [evets, setEvets] = useState([[]]);
  const [index, setindex] = useState(0);

  useEffect(() => {
    customAxios.get("/Event/getAll").then((res) => {
      setEvets(divideArrayIntoSubarrays(res?.data?.Actuality, 4));
    });
  }, []);

  // useEffect(() => {
  //   setEvets(divideArrayIntoSubarrays(events, 4));
  // }, [events]);

  const GoToEvent = (id) => {
    navig(`/campus/actualites/${id}`);
  };

  return (
    <div className={styles.main}>
      <LeftColoredTitle>Actualités</LeftColoredTitle>

      <div className={styles.container2}>
        <div className={styles.events}>
          {evets[index].map((event, key) => {
            return (
              <ZoomIn
                key={key}
                offset={-500}
                className={styles.event_container}
                onClick={() => {
                  GoToEvent(event._id);
                }}
              >
                <EventCard
                  onClick={GoToEvent}
                  // date="08 . 04 . 2023"
                  date={DateDoted(event.date)}
                  img={event.main_img}
                  title={event.title}
                />
              </ZoomIn>
            );
          })}
        </div>

        <Swippers size={evets.length} current={index} setCurrent={setindex} />
        <HR1 />
      </div>
    </div>
  );
}

export default Actuality;
