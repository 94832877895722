import React, { useState } from "react";
import styles from "./styles.module.scss";
import { createArray } from "../../functions/Arrays";

export const Swippers = ({ size = 0, current = 0, setCurrent = () => {} }) => {
  return (
    <div className={styles["swipper-container"]}>
      <div className={styles["swipper"]}>
        {createArray(size).map((item, key) => {
          return (
            <span
              key={key}
              className={`
              ${styles.circle}  
              ${current === key && styles.active}
              `}
              onClick={() => {
                setCurrent(key);
              }}
            />
          );
        })}
      </div>
    </div>
  );
};
