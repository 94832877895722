import React from "react";
import styles from "./styles.module.scss";
import SecondNavbar from "../../layouts/Navbars/second_navbar/SecondNavbar";
import MainNavbar from "../../layouts/Navbars/main_navbar/MainNavbar";
import ContactSection from "../../layouts/ContactSecion/ContactSection";

import Header from "./sections/Header/Header";
import Body from "./sections/Body/Body";
import Footer from "../../layouts/Footer/Footer";

function Faculty() {
  return (
    <div className={styles["main"]}>
      <MainNavbar />
      <SecondNavbar />
      <FacultyCompo />
      <ContactSection />
      <Footer />
    </div>
  );
}

const FacultyCompo = () => {
  return (
    <div className={styles["faculty"]}>
      <Header />
      <Body />
    </div>
  );
};

export default Faculty;
