import React, { useState } from "react";
import styles from "./styles.module.scss";

import PageContainer from "../../Common/PageContainer/PageContainer";
import LeftColoredTitle from "../../components/Titles/LeftColoredTitle";

import { InscrireBtn } from "../../components/buttons/Buttons";

import { nationals, pays } from "./data";
import {
  init_data,
  validation,
  trackFacebookConversionsAPI,
  trackFacebookPixel,
} from "./validation";
import { inscription } from "../../services/inscription";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";

function Inscription() {
  const [form, setForm] = useState({ ...init_data });
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handle_change = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handle_submit = () => {
    if (validation(form)) {
      setLoading(true);
      inscription(form)
        .then((resp) => {
          setForm({ ...init_data });
          setLoading(false);
          toast.success("Inscription effectuée avec succès");
          localStorage.setItem("Nom", form.Nom);
          localStorage.setItem("Prenom", form.Prenom);
          localStorage.setItem(
            "Type",
            form.nationalite === "TUNISIE" ? "tn" : "not-tn"
          );

          let event_name = "pre-inscription";

          // Facebook Pixel tracking
          trackFacebookPixel(event_name, {
            content_name: event_name,
            status: "lead",
          });

          trackFacebookConversionsAPI(
            event_name,
            {
              em: form.Email,
              ph: form.Telephone,
            },
            {
              content_name: event_name,
              status: "lead",
            }
          );

          navigate(`/inscription-success`);
        })
        .catch((error) => {
          toast.success("Server error");
          setLoading(false);
        });
    }
  };

  return (
    <div>
      <PageContainer title="Inscription" links={[]} with_links={false}>
        <div className={styles.container}>
          <LeftColoredTitle>Informations générales</LeftColoredTitle>

          <div className={styles.form}>
            <div className={styles.w100}>
              <label> Civilité * </label>
              <select
                onChange={handle_change}
                value={form.Civilite}
                name="Civilite"
                defaultValue={null}
              >
                <option selected>- Sélectionner -</option>
                <option value="Mr">Mr</option>
                <option value="Mme">Mme</option>
              </select>
            </div>

            <div className={styles.w50}>
              <label> Nom* </label>

              <input value={form.Nom} name="Nom" onChange={handle_change} />
            </div>

            <div className={styles.w50}>
              <label> Prénom* </label>

              <input
                value={form.Prenom}
                name="Prenom"
                onChange={handle_change}
              />
            </div>

            <div className={styles.w50}>
              <label> Email* </label>

              <input value={form.Email} name="Email" onChange={handle_change} />
            </div>

            <div className={styles.w50}>
              <label> Téléphone / Numéro WhatsApp* </label>

              <input
                value={form.Telephone}
                name="Telephone"
                onChange={handle_change}
              />
            </div>

            <div className={styles.w50}>
              <label>Pays de résidence* </label>

              <select value={form.pays} onChange={handle_change} name="pays">
                <option selected>- Sélectionner -</option>
                {pays.map((item, key) => {
                  return (
                    <option key={key} value={item}>
                      {item}
                    </option>
                  );
                })}
              </select>
            </div>

            <div className={styles.w50}>
              <label>Adresse </label>

              <input
                value={form.adresse}
                name="adresse"
                onChange={handle_change}
              />
            </div>

            <div className={styles.w50}>
              <label>Nationalité* </label>

              <select
                value={form.nationalite}
                onChange={handle_change}
                name="nationalite"
              >
                <option selected>- Sélectionner -</option>
                {nationals.map((item, key) => {
                  return (
                    <option key={key} value={item}>
                      {item}
                    </option>
                  );
                })}
              </select>
            </div>

            <div className={styles.w50}>
              <label>Niveau d'études actuel* </label>

              <select
                value={form.Niveau}
                onChange={handle_change}
                name="Niveau"
              >
                <option selected={true}>- Sélectionner -</option>

                <option value="Baccalauréat en cours">
                  Baccalauréat en cours
                </option>
                <option value="Baccalauréat">Baccalauréat</option>
                <option value="Bac + 1">Bac + 1</option>
                <option value="Bac + 2">Bac + 2</option>
                <option value="Bac + 3">Bac + 3</option>
                <option value="Bac + 4">Bac + 4</option>
                <option value="Bac + 5">Bac + 5</option>
              </select>
            </div>

            <div className={styles.w50}>
              <label>Filière choisie* </label>

              <select
                value={form.Filiere}
                onChange={handle_change}
                name="Filiere"
              >
                <option
                  style={{ fontWeight: "bold" }}
                  selected={true}
                  disabled={true}
                >
                  - Sélectionner -
                </option>

                <option
                  value="ÉCOLE D’ARCHITECTURE ET DESIGN"
                  style={{ fontWeight: "bold" }}
                  disabled={true}
                >
                  ÉCOLE D’ARCHITECTURE ET DESIGN
                </option>

                <option value="Diplôme National d’Architecte">
                  Diplôme National d’Architecte
                </option>

                <option
                  value="ÉCOLE DE DROIT"
                  style={{ fontWeight: "bold" }}
                  disabled={true}
                >
                  ÉCOLE DE DROIT
                </option>

                <option value="Licence Droit Public">
                  Licence Droit Public
                </option>

                <option value="Licence Droit Privé">Licence Droit Prive</option>

                <option value="Master Droit des Affaires">
                  Master Droit des Affaires
                </option>

                <option value="Master Sciences Politiques">
                  Master Sciences Politiques
                </option>

                <option value="Master Droit Judiciaire">
                  Master Droit Judiciaire
                </option>

                <option value="Master Droit international et organisations Internationales (OADA)">
                  Master Droit international et organisations Internationales
                  (OADA)
                </option>

                <option
                  value="ÉCOLE DE BUSINESS"
                  style={{ fontWeight: "bold" }}
                  disabled={true}
                >
                  ÉCOLE DE BUSINESS
                </option>

                <option value="Licence en Management">
                  Licence en Management
                </option>

                <option value="Licence en Comptabilité">
                  Licence en Comptabilité
                </option>

                <option value="Licence en Finance">Licence en Finance</option>

                <option value="Licence Business Management (100% ENGLISH)">
                  Licence Business Management (100% ENGLISH)
                </option>

                <option value="Licence en Business intelligence (BI)">
                  Licence en Business intelligence (BI)
                </option>

                <option value="Licence en Marketing">
                  Licence en Marketing
                </option>

                <option value="Master en Marketing Digital et data analytics">
                  Master Marketing Digital et data analytics
                </option>

                <option value="Master Comptabilité - Contrôle - Audit (CCA)">
                  Master Comptabilité - Contrôle - Audit (CCA)
                </option>

                <option value="Master Ingénierie Fintech">
                  Master Ingénierie Fintech
                </option>

                <option
                  value="ÉCOLE D'INFORMATIQUE ET TECHNOLOGIES"
                  style={{ fontWeight: "bold" }}
                  disabled={true}
                >
                  ÉCOLE D'INFORMATIQUE ET TECHNOLOGIES
                </option>

                <option value="Cycle ingénieur informatique">
                  Cycle ingénieur informatique
                </option>

                <option
                  value="ÉCOLE DE DESIGN"
                  style={{ fontWeight: "bold" }}
                  disabled={true}
                >
                  ÉCOLE DE DESIGN
                </option>

                <option value="Licence Design: ESPACE">
                  Licence Design : ESPACE
                </option>

                <option value="Licence Design : IMAGE">
                  Licence Design : IMAGE
                </option>

                <option value="Master En architecture D'intérieur">
                  Master En architecture D'intérieur
                </option>
              </select>
            </div>

            <div className={styles.w50}>
              <label>Comment avez-vous connu l'UIK ?* </label>

              <select
                value={form.reponse}
                onChange={handle_change}
                name="reponse"
              >
                <option selected={true}>- Sélectionner -</option>

                <option value="Recherche sur le Web">
                  Recherche sur le Web
                </option>
                <option value="Facebook">Facebook</option>
                <option value="Instagram">Instagram</option>
                <option value="Journées Portes ouvertes">
                  Journées Portes ouvertes
                </option>
                <option value="Salons ou évènements d’orientation">
                  Salons ou évènements d’orientation
                </option>
                <option value="Visite de lycées">Visite de lycées</option>
                <option value="Média">Média</option>
                <option value="Panneau d’affichage">Panneau d’affichage</option>
                <option value="Bouche à oreille (ancien étudiant, professeur ou autres)">
                  Bouche à oreille (ancien étudiant, professeur ou autres)
                </option>
              </select>
            </div>

            <div className={styles.w100}>
              <label>Message</label>

              <textarea
                value={form.Message}
                onChange={handle_change}
                name="Message"
                rows={5}
              />
            </div>
          </div>
          <div className={styles.send}>
            <InscrireBtn
              loading={loading}
              txt_loading="Chargement..."
              onClick={handle_submit}
            >
              Envoyer
            </InscrireBtn>
          </div>
        </div>
      </PageContainer>
    </div>
  );
}

export default Inscription;
