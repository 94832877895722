import React, { useState } from "react";
import styles from "./styles.module.scss";
import quote2 from "../../../../assets/svgs/icons/quote1.svg";

import t1 from "../../../../assets/images/teachers/t1.jpg";

const Header = () => {
  const [carossel, setcarossel] = useState(1);
  const items = [1, 2, 3, 4, 5];

  const handle_select = (item) => {
    setcarossel(item);
  };

  return (
    <div className={styles["header"]}>
      <img src={t1} alt="" className={styles["teacher"]} />

      <div className={styles["content"]}>
        <div className={styles["slogon"]}>
          <img src={quote2} alt="" />
          <h2>Lorem ipsum dolor sit amet.</h2>
        </div>
      </div>

      <div className={styles["swipper-container"]}>
        <div className={styles["swipper"]}>
          {items.map((item, key) => {
            return (
              <span
                key={key}
                className={`
              ${styles.circle} 
              ${item === carossel && styles.active}
              `}
                onClick={() => {
                  handle_select(item);
                }}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Header;
