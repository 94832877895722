import keys from "./degrees.keys";

export const InitialState = {
  degree: null,
  degrees: [],
  univ: null,
};

export const DegreesReducer = (state = { ...InitialState }, action) => {
  switch (action.type) {
    case keys.set_degree:
      return { ...state, degree: action.value };
    case keys.set_univ:
      return { ...state, univ: action.value };
    case keys.set_degrees:
      return { ...state, degrees: action.value };
    default:
      return state;
  }
};
