import React from "react";
import styles from "./styles.module.scss";

function MiniRightColoredTitle({
  children,
  fontSize = 20,
  active = false,
  onClick,
}) {
  return (
    <h1
      onClick={onClick}
      className={`${styles["main"]} ${active ? styles["active"] : ""}`}
      style={{ fontSize: `${fontSize}px` }}
    >
      {children}
    </h1>
  );
}

export default MiniRightColoredTitle;
