import React from "react";
import styles from "./styles.module.scss";
import ImgBack from "../../components/Image/ImgBack";

function EventCard({ title, date, img }) {
  return (
    <div className={styles.main}>
      <div className={styles.img}>
        <ImgBack src={img} alt={title} />
      </div>
      <div className={styles.data}>
        <h2>{title}</h2>
        <p>{date}</p>
      </div>
    </div>
  );
}

export default EventCard;
