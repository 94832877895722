import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";

import PageContainer from "../../Common/PageContainer/PageContainer";
import LeftColoredTitle from "../../components/Titles/LeftColoredTitle";

function SuccInsctiption() {
  const [data, setData] = useState({
    Nom: "",
    Prenom: "",
    Type: "",
  });

  useEffect(() => {
    setData({
      Nom: localStorage.getItem("Nom"),
      Prenom: localStorage.getItem("Prenom"),
      Type: localStorage.getItem("Type"),
    });
  }, []);

  return (
    <div>
      <PageContainer title="Inscription" links={[]} with_links={false}>
        <div className={styles.container}>
          <LeftColoredTitle>
            Pré-inscription effectuée avec succès!
          </LeftColoredTitle>

          {data.Type === "tn" ? (
            <InfoForTn Nom={data.Nom} Prenom={data.Prenom} />
          ) : (
            <InfoForNotTn Nom={data.Nom} Prenom={data.Prenom} />
          )}
        </div>
      </PageContainer>
    </div>
  );
}

const InfoForTn = ({ Nom, Prenom }) => {
  return (
    <div className={styles.succ}>
      <p>Cher(e) {`${Nom} ${Prenom}`}</p>
      <p>
        Nous vous remercions pour l'intérêt que vous portez à notre université.
        Votre demande de pré-inscription effectuée sur notre site web a bien été
        reçue.
      </p>
      <p>
        Un conseiller d'orientation prendra contact avec vous dans les plus
        brefs délais afin de vous assister pour le choix de filière ainsi que
        les démarches d'admission.
      </p>
      <p>
        Nous vous invitons à nous transmettre votre dossier de candidature
        scanné par mail :
      </p>
      <p>- Dernier diplôme obtenu</p>
      <p>-Les derniers relevés des notes</p>
      <p>- Copie de la CIN</p>
      <p>
        Nous restons à votre entière disposition pour répondre à toutes vos
        questions. N'hésitez pas à nous contacter par telephone ou email.
      </p>
      <p>Université Ibn Khaldoun</p>
      <p>
        <a href="https://wa.me/21654083083">+216 54 083 083</a>
      </p>
      <p>
        <a href="mailto:admission@uik.ens.tn">admission@uik.ens.tn</a>
      </p>
    </div>
  );
};

const InfoForNotTn = ({ Nom, Prenom }) => {
  return (
    <div className={styles.succ}>
      <p>
        Cher(e) <strong>{`${Nom} ${Prenom}`}</strong>
      </p>
      <p>
        Nous vous remercions pour l'intérêt que vous portez à notre université.
        Votre demande de pré-inscription effectuée sur notre site web a bien été
        reçue.
      </p>
      <p>
        Un conseiller d'orientation prendra contact avec vous dans les plus
        brefs délais afin de vous assister pour le choix de filière ainsi que
        les démarches d'admission.
      </p>
      <p>
        Nous vous invitons à nous transmettre votre dossier de candidature
        scanné par mail :
      </p>
      <p>- Dernier diplôme obtenu</p>
      <p>- Les derniers relevés des notes</p>
      <p>- Copie du passeport (Si disponible)</p>
      <p>
        Nous restons à votre entière disposition pour répondre à toutes vos
        questions. N'hésitez pas à nous contacter par telephone ou email.
      </p>
      <p>Université Ibn Khaldoun</p>
      <p>
        <strong>Contact: Tél/WhatsApp: </strong>{" "}
        <a href="https://wa.me/21650810000">+216 50 810 000</a>
      </p>
      <p>
        <strong>Email: </strong>{" "}
        <a href="mailto:admission@uik.ens.tn">admission@uik.ens.tn</a>
      </p>
    </div>
  );
};

export default SuccInsctiption;
