import React from "react";
import styles from "./styles.module.scss";
import RightColoredTitle from "../../../../components/RightColoredTitle/RightColoredTitle";

import { chef_depart, direction, teachers } from "./fakedata";
import TeacherCard from "../../../../components/Cards/TeacherCard/Teachers";
import { InscrireBtn } from "../../../../components/buttons/Buttons";

const Body = () => {
  return (
    <div className={styles["body"]}>
      <div className={styles["container"]}>
        <RightColoredTitle className={styles.title}>
          Direction
        </RightColoredTitle>
        <div className={`${styles["teachers"]}`}>
          {direction.map((item, key) => {
            return (
              <TeacherCard
                className={styles["teacher"]}
                key={key}
                data={item}
              />
            );
          })}

          <TeacherCard
            className={`${styles["teacher"]} ${styles["transparent"]}`}
            data={{ img: "", title: "" }}
          />
        </div>

        <RightColoredTitle className={styles.title}>
          Chefs de départements
        </RightColoredTitle>
        <div className={styles["teachers"]}>
          {chef_depart.map((item, key) => {
            return (
              <TeacherCard
                className={styles["teacher"]}
                key={key}
                data={item}
              />
            );
          })}
        </div>

        <RightColoredTitle className={styles.title}>
          Enseignants
        </RightColoredTitle>
        <div className={styles["teachers"]}>
          {teachers.map((item, key) => {
            return (
              <TeacherCard
                className={styles["teacher"]}
                key={key}
                data={item}
              />
            );
          })}
        </div>

        <div className={styles["btn-container"]}>
          <InscrireBtn>Découvrez-en plus sur l'UIK</InscrireBtn>
        </div>
      </div>
    </div>
  );
};

export default Body;
