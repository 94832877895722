import React, { useEffect, useState } from "react";
import styles from "./Alternance.module.scss";

import { useLocation } from "react-router-dom";

import AlterDegree from "./SubPages/AlterDegree";
import MainNavbar from "../../layouts/Navbars/main_navbar/MainNavbar";
import SecondNavbar from "../../layouts/Navbars/second_navbar/SecondNavbar";
import ImageSection from "./VideoSection/ImageSection";
import ContactSection from "../../layouts/ContactSecion/ContactSection";
import Footer from "../../layouts/Footer/Footer";
import axios from "../../utils/axios";

function ALTERNANCE() {
  const location = useLocation();
  const [formation, setFormation] = React.useState(null);

  const [data, setData] = useState([]);

  useEffect(() => {
    axios
      .get("/Formation/getAll")
      .then((res) => {
        setData(res.data.Formation);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    const currentFormation = data.find(
      (item) =>
        item.title.toLowerCase().split(" ").join("-") ===
        location.pathname.split("/")[2]
    );
    if (currentFormation) {
      setFormation(currentFormation);
    } else {
      setFormation(null);
    }
  }, [location, data]);

  if (!formation) return <></>;

  return (
    <div className={styles.main}>
      <MainNavbar />
      <SecondNavbar />
      <ImageSection url={formation.mainImg} title={formation.title} />
      <div className={styles.container2}>
        <div className={styles.body}>
          <div className={styles.page}>
            <AlterDegree />
          </div>
        </div>
      </div>
      <ContactSection />
      <Footer />
    </div>
  );
}

export default ALTERNANCE;
