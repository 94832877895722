import React from "react";

import { useEffect, useState } from "react";
// import { SelectPicker } from "rsuite";
import { getCookie, hasCookie, setCookie } from "cookies-next";
import Select from "./Select";

const googleTranslateElementInit = () => {
  new window.google.translate.TranslateElement(
    {
      pageLanguage: "auto",
      autoDisplay: false,
      includedLanguages: "fr,en,ar", // If you remove it, by default all google supported language will be included
      layout: window.google.translate.TranslateElement.InlineLayout.SIMPLE,
    },
    "google_translate_element"
  );
};

const langs = {
  US: "/auto/en",
  FR: "",
};

function Language({ isUp = false }) {
  useEffect(() => {
    var addScript = document.createElement("script");
    addScript.setAttribute(
      "src",
      "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
    );
    document.body.appendChild(addScript);
    window.googleTranslateElementInit = googleTranslateElementInit;
  }, []);

  const HandlelangChange = (e) => {
    if (hasCookie("googtrans")) {
      setCookie("googtrans", decodeURI(e));
    } else {
      setCookie("googtrans", e);
    }
  };

  useEffect(() => {
    let lang = localStorage.getItem("lang");
    HandlelangChange(langs[lang]);
  }, []);

  return (
    <div>
      <Select isUp={isUp} />
    </div>
  );
}

export default Language;
