import React, { useEffect } from "react";
import styles from "./styles.module.scss";
import MainNavbar from "../../layouts/Navbars/main_navbar/MainNavbar";
import SecondNavbar from "../../layouts/Navbars/second_navbar/SecondNavbar";
import VideoSection from "./VideoSection/VideoSection";
import BodyPage from "./BodyPage/BodyPage";
import ContactSection from "../../layouts/ContactSecion/ContactSection";
import Footer from "../../layouts/Footer/Footer";

function University({ data }) {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "instant",
    });
  }, []);

  return (
    <div className={styles["main"]}>
      <MainNavbar />
      <SecondNavbar />
      <VideoSection data={data} />
      <BodyPage data={data} />
      <ContactSection />
      <Footer />
    </div>
  );
}

export default University;
