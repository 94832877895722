import React, { useEffect } from "react";
import styles from "./styles.module.scss";

import PageContainer from "../../Common/PageContainer/PageContainer";
import LeftColoredTitle from "../../components/Titles/LeftColoredTitle";

import international_img from "../../assets/images/international/INTERNATIONAL.jpg";

import partner from "../../assets/images/Partner.png";

function International() {
  return (
    <div>
      <PageContainer title="INTERNATIONAL" links={[]} with_links={false}>
        <div className={styles.container}>
          <LeftColoredTitle>Nos partenaires internationaux</LeftColoredTitle>

          <img src={international_img} alt="" className={styles.full_img} />

          <p className={styles.description}>
            L'Université Ibn Khaldoun est fortement engagée dans une ouverture
            internationale à travers son vaste réseau de partenariats avec des
            universités et des organismes renommés en Afrique du Nord, en
            Europe, en Amérique du Nord et au Moyen-Orient. Cette ouverture vers
            l'international offre aux étudiants de l'université des opportunités
            uniques d'échanges académiques, de collaborations de recherche et
            d'enrichissement culturel. Grâce à ces partenariats, les étudiants
            peuvent participer à des programmes d'échange qui leur permettent
            d'étudier dans des institutions prestigieuses à l'étranger,
            d'acquérir une expérience internationale précieuse et de développer
            des compétences interculturelles. De plus, les collaborations de
            recherche favorisent le partage des connaissances et des expertises,
            renforçant ainsi la réputation de l'université en tant qu'acteur
            majeur dans le domaine de l'enseignement supérieur et de la
            recherche. Cette ouverture vers l'international permet également
            d'établir des liens durables avec des institutions académiques et
            des organismes internationaux, favorisant ainsi le développement de
            projets communs, les échanges intellectuels et la mobilité des
            étudiants et des enseignants. L'université Ibn Khaldoun continue
            ainsi à s'affirmer comme un acteur incontournable dans l'éducation
            supérieure en favorisant l'échange et la collaboration à l'échelle
            internationale.
          </p>

          {/* <div className={styles.partners}>
            <img     src={partner} className={styles.partner} alt="" />
            <img     src={partner} className={styles.partner} alt="" />
            <img     src={partner} className={styles.partner} alt="" />
            <img     src={partner} className={styles.partner} alt="" />
            <img     src={partner} className={styles.partner} alt="" />
            <img     src={partner} className={styles.partner} alt="" />
          </div> */}
        </div>
      </PageContainer>
    </div>
  );
}

export default International;
