import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";

import MiniRightColoredTitle from "../../components/MiniRightColoredTitle/MiniRightColoredTitle";
import ImabeBack from "../../components/Image/ImgBack";
import CardInfo from "../../components/Cards/CardInfo/CardInfo";

import {
  FaRegClock,
  FaGlobeAfrica,
  FaExclamationTriangle,
} from "react-icons/fa";

import { FadeUp } from "../../components/Animations/Fade";

import { ZoomIn } from "../../components/Animations/Zoom";
import useResolution from "../../hooks/useResolution";

function DegreePage({ degree = null }) {
  const [selected, set_selected] = useState("Présentation");
  const [selects, set_selects] = useState(["Présentation", "Curriculum"]);
  let is_eng = degree?.langue === "English";

  useEffect(() => {
    console.log(degree);
    let les_choix = ["Présentation", "Curriculum"];

    if (degree?.certification) {
      les_choix = [...les_choix, "Certifications professionnelles"];
    }

    if (degree?.objectifs) {
      les_choix = [...les_choix, "Objectifs"];
    }
    if (degree?.pres_prof) {
      les_choix = [...les_choix, "Perspectives professionnelles"];
    }

    set_selects(les_choix);
  }, [degree]);

  if (degree === null) {
    return "Loading...";
  }

  const SelectedTranslate = (item) => {
    let langs = {
      Présentation: { fr: "Présentation", eng: "Presentation" },
      Curriculum: { fr: "Curriculum", eng: "Curriculum" },
      "Certifications professionnelles": {
        fr: "Certifications professionnelles",
        eng: "Professional certifications",
      },
      Objectifs: { fr: "Objectifs", eng: "Objectives" },
      "Perspectives professionnelles": {
        fr: "Perspectives professionnelles",
        eng: "Career prospects",
      },
    };

    return is_eng ? langs[item].eng : langs[item].fr;
  };

  return (
    <div className={styles["main"]}>
      <div className={styles["cards-infos"]}>
        <FadeUp className={styles["cards-cont"]}>
          <CardInfo title={is_eng ? "Duration" : "Durée"} Icon={FaRegClock}>
            <p>
              {degree.duration_type} :
              <strong style={{ fontWeight: "500", padding: "0px 0px 0px 5px" }}>
                {degree.duration}
              </strong>
            </p>
          </CardInfo>
        </FadeUp>

        <FadeUp className={styles["cards-cont"]}>
          <CardInfo
            title={is_eng ? "Admission requirement" : "Condition d'admission"}
            Icon={FaExclamationTriangle}
          >
            <p>{degree.cond_adm}</p>
          </CardInfo>
        </FadeUp>

        <FadeUp className={styles["cards-cont"]}>
          <CardInfo title={"Language"} Icon={FaGlobeAfrica}>
            <p>{degree.langue}</p>
          </CardInfo>
        </FadeUp>
      </div>

      <div className={styles["choices"]}>
        {selects.map((item, key) => {
          return (
            <MiniRightColoredTitle
              key={key}
              active={item === selected}
              onClick={() => {
                set_selected(item);
              }}
            >
              {SelectedTranslate(item)}
            </MiniRightColoredTitle>
          );
        })}
      </div>

      {selected === "Présentation" && <PresentationSec degree={degree} />}
      {selected === "Curriculum" && <Curriculum degree={degree} />}
      {selected === "Certifications professionnelles" && (
        <CerteficationsPage degree={degree} />
      )}
      {selected === "Objectifs" && <Objectifs degree={degree} />}
      {selected === "Perspectives professionnelles" && (
        <PerspecttivesProf degree={degree} />
      )}
    </div>
  );
}

export const PresentationSec = ({ degree = null }) => {
  return (
    <div>
      <div className={styles["pres-sec"]}>
        <p className={styles["descript"]}>{degree.long_desc}</p>
      </div>
    </div>
  );
};

export const Objectifs = ({ degree }) => {
  return (
    <div className={styles["pres-sec"]}>
      <div
        className={styles["descript"]}
        dangerouslySetInnerHTML={{ __html: degree.objectifs }}
      ></div>
    </div>
  );
};

export const ShowDangerHtml = ({ text = "<p></p>" }) => {
  return (
    <div className={styles["pres-sec"]}>
      <div
        className={styles["descript"]}
        dangerouslySetInnerHTML={{ __html: text }}
      ></div>
    </div>
  );
};

export const PerspecttivesProf = ({ degree }) => {
  return (
    <div className={styles["pres-sec"]}>
      <div
        className={styles["descript"]}
        dangerouslySetInnerHTML={{ __html: degree.pres_prof }}
      />
    </div>
  );
};

export const CerteficationsPage = ({ degree }) => {
  const { sm } = useResolution();

  const { certification } = degree;

  return (
    <ZoomIn className={styles["cert-cont"]}>
      <h2>{certification.title}</h2>
      <p>{certification.description}</p>

      <ImabeBack
        src={sm ? certification.image_mobile : certification.image_pc}
        alt="Certifications"
      />
    </ZoomIn>
  );
};

export const Curriculum = ({ degree }) => {
  const { sm } = useResolution();

  return (
    <div className={styles["Curriculum"]}>
      {degree.semesters.map((sem, key) => {
        return (
          <FadeUp key={key}>
            <h2 className={styles["head"]}>{sem.title}</h2>
            {sem.sub_title && (
              <h2 className={styles["sub-head"]}>{sem?.sub_title}</h2>
            )}

            <table>
              {sem.subjects
                .reduce((result, current, index) => {
                  const groupIndex = Math.floor(index / (sm ? 1 : 2));

                  if (!result[groupIndex]) {
                    result[groupIndex] = [];
                  }

                  result[groupIndex].push(current);

                  return result;
                }, [])
                .map((item, key2) => {
                  return (
                    <tr key={key2}>
                      <td>{item[0]}</td>
                      {!sm && <td>{item[1]}</td>}
                    </tr>
                  );
                })}
            </table>
          </FadeUp>
        );
      })}
    </div>
  );
};

export const OneColTable = ({ data = [] }) => {
  console.log(data);
  return (
    <div className={styles["Curriculum"]}>
      {data.map((part, key) => {
        return (
          <FadeUp key={key}>
            {part.title && <h2 className={styles["head"]}>{part.title}</h2>}
            {part.sub_title && (
              <h2 className={styles["sub-head"]}>{part?.sub_title}</h2>
            )}
            <table>
              {part?.content?.map((row, key2) => {
                return (
                  <tr key={key2}>
                    <td>{row}</td>
                  </tr>
                );
              })}
            </table>
          </FadeUp>
        );
      })}
    </div>
  );
};

export default DegreePage;
