import t1 from "../../../../assets/images/teachers/teachers_v2/1.png";
import t2 from "../../../../assets/images/teachers/teachers_v2/2.png";
import t3 from "../../../../assets/images/teachers/teachers_v2/3.png";
import t4 from "../../../../assets/images/teachers/teachers_v2/4.png";
import t5 from "../../../../assets/images/teachers/teachers_v2/5.png";
import t6 from "../../../../assets/images/teachers/teachers_v2/6.png";
import t7 from "../../../../assets/images/teachers/teachers_v2/7.png";
import t8 from "../../../../assets/images/teachers/teachers_v2/8.png";
import t9 from "../../../../assets/images/teachers/teachers_v2/9.png";
import exemple from "../../../../assets/images/teachers/teachers_v2/exemple.png";

export const direction = [
  { img: t1, title: "Lorem ipsun" },
  { img: t2, title: "Lorem ipsun" },
  { img: t3, title: "Lorem ipsun" },
  { img: t4, title: "Lorem ipsun" },
];

export const chef_depart = [
  { img: t5, title: "Lorem ipsun" },
  { img: t6, title: "Lorem ipsun" },
  { img: t7, title: "Lorem ipsun" },
  { img: t8, title: "Lorem ipsun" },
  { img: t9, title: "Lorem ipsun" },
];

export const teachers = [
  { img: exemple, title: "Lorem ipsun" },
  { img: exemple, title: "Lorem ipsun" },
  { img: exemple, title: "Lorem ipsun" },
  { img: exemple, title: "Lorem ipsun" },
  { img: exemple, title: "Lorem ipsun" },
  { img: exemple, title: "Lorem ipsun" },
  { img: exemple, title: "Lorem ipsun" },
  { img: exemple, title: "Lorem ipsun" },
  { img: exemple, title: "Lorem ipsun" },
  { img: exemple, title: "Lorem ipsun" },
  { img: exemple, title: "Lorem ipsun" },
  { img: exemple, title: "Lorem ipsun" },
  { img: exemple, title: "Lorem ipsun" },
  { img: exemple, title: "Lorem ipsun" },
  { img: exemple, title: "Lorem ipsun" },
];
