import ico1 from "../../../../assets/svgs/stats/a.svg";
import ico2 from "../../../../assets/svgs/stats/b.svg";
import ico3 from "../../../../assets/svgs/stats/c.svg";
import ico4 from "../../../../assets/svgs/stats/d.svg";
import ico5 from "../../../../assets/svgs/stats/e.svg";
import ico6 from "../../../../assets/svgs/stats/f.svg";
import ico7 from "../../../../assets/svgs/stats/g.svg";

import CountDownOnShow from "../../../../components/CountDown/CountDownInShow";

export const data = {
  p1: [
    {
      img: ico1,
      numb: (
        <span>
          +<CountDownOnShow number={18} />
        </span>
      ),
      desc: <span> ANS D’EXCELLENCE</span>,
      blue: false,
    },
    {
      img: ico2,
      numb: (
        <span>
          <CountDownOnShow number={5} />
        </span>
      ),
      desc: <span>ECOLES</span>,
      blue: true,
    },
    {
      img: ico7,
      numb: (
        <span>
          +<CountDownOnShow number={20} />
        </span>
      ),
      desc: <span>NATIONALITES</span>,
      blue: false,
    },
    {
      img: ico6,
      numb: (
        <span>
          +<CountDownOnShow number={1000} />
        </span>
      ),
      desc: <span>ETUDIANTS</span>,
      blue: true,
    },
  ],
  p2: [
    {
      img: ico4,
      numb: (
        <span>
          +<CountDownOnShow number={250} />
        </span>
      ),
      desc: (
        <span>
          ENSEIGNANTS ET <br /> PROFESSIONNELS
        </span>
      ),
      blue: true,
    },
    {
      img: ico3,
      numb: (
        <span>
          +<CountDownOnShow number={30} />
        </span>
      ),
      desc: (
        <span>
          PARTENAIRES NATIONAUX <br /> ET INTERNATIONAUX
        </span>
      ),
      blue: false,
    },
    {
      img: ico5,
      numb: (
        <span>
          +<CountDownOnShow number={5000} />
        </span>
      ),
      desc: <span>ALUMNI</span>,
      blue: false,
    },
  ],
};

export const data2 = [
  {
    numb: (
      <span>
        <CountDownOnShow number={15000} /> m²
      </span>
    ),
    desc: (
      <span>
        CAMPUS AVEC COMPLEXE <br /> SPORTIF OMNISPORTS
      </span>
    ),
  },

  {
    numb: (
      <span>
        <CountDownOnShow number={4500} /> m²
      </span>
    ),
    desc: <span>SITE DE MONTPLAISIR</span>,
  },
];
