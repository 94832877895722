import React, { useState } from "react";
import styles from "./styles.module.scss";
import LeftColoredTitle from "../../../../components/Titles/LeftColoredTitle";
import { InscrireBtn } from "../../../../components/buttons/Buttons";
import { GrClose } from "react-icons/gr";
import { toast } from "react-hot-toast";
import customAxios from "../../../../utils/axios";

const init_data = {
  name: "",
  tel: "",
  email: "",
};

const validation = (form = init_data) => {
  if (!form.name) {
    toast.error("Nom est obligatoire");
    return false;
  }

  if (!form.tel) {
    toast.error("Telephone est obligatoire");
    return false;
  }

  if (!form.email) {
    toast.error("Email est obligatoire");
    return false;
  }

  return true;
};

function PopupInsc({ close, formation }) {
  const [form, setForm] = useState({ ...init_data });
  const [loading, setLoading] = useState(false);

  const handle_change = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handle_submit = () => {
    if (validation(form)) {
      setLoading(true);

      customAxios
        .post("/FormationDemandRoute/add", {
          name: form.name,
          tel: form.tel,
          email: form.email,
          formation: formation._id,
        })
        .then((resp) => {
          setForm({ ...init_data });
          toast.success("Inscription effectuée avec succès");
          setLoading(false);
          close();
        })
        .catch((error) => {
          setLoading(false);
          toast.success("Server error");
        });
    }
  };

  return (
    <div className={styles.main}>
      <div className={styles.body}>
        <div className={styles.close}>
          <GrClose className={styles.close_icon} onClick={close} />
        </div>

        <LeftColoredTitle>Informations générales</LeftColoredTitle>
        <div className={styles.form}>
          <div className={styles.w100}>
            <label> Nom & Prénom * </label>
            <input onChange={handle_change} value={form.NOM} name="name" />
          </div>

          <div className={styles.w100}>
            <label> Telephone * </label>
            <input onChange={handle_change} value={form.TELEPHONE} name="tel" />
          </div>

          <div className={styles.w100}>
            <label> Email * </label>
            <input onChange={handle_change} value={form.EMAIL} name="email" />
          </div>
        </div>

        <div className={styles.send}>
          <InscrireBtn
            onClick={handle_submit}
            loading={loading}
            txt_loading="Chargement..."
          >
            S'inscrire
          </InscrireBtn>
        </div>
      </div>
    </div>
  );
}

export default PopupInsc;
