import "./styles.scss";
import { MapContainer, TileLayer, Marker, Popup, Tooltip } from "react-leaflet";
// import MarkerClusterGroup from "react-leaflet-markercluster";

const position = [36.81723, 10.18525];
const position2 = [36.8821, 10.2276];

const GoogleMap = () => {
  return (
    <MapContainer
      className="markercluster-map"
      center={position}
      zoom={10.5}
      maxZoom={18}
    >
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
      />

      <Marker position={position}>
        <Popup keepInView>UIK - Université Ibn Khaldoun (Montplaisir)</Popup>
        <Tooltip>UIK Montplaisir</Tooltip>
      </Marker>

      <Marker position={position2}>
        <Popup keepInView>UIK - Université IBN KHALDOUN</Popup>
        <Tooltip>UIK</Tooltip>
      </Marker>
    </MapContainer>
  );
};

export default GoogleMap;
