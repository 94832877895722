import React from "react";
import styles from "./styles.module.scss";

function ScrollNow({ onClick = () => {} }) {
  return (
    <div className={styles["container"]} onClick={onClick}>
      <div className={styles["chevron"]}></div>
      <div className={styles["chevron"]}></div>
      <div className={styles["chevron"]}></div>
    </div>
  );
}

export default ScrollNow;
