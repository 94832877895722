import React from "react";
import styles from "./styles.module.scss";
import quote2 from "../../../../assets/svgs/icons/quote1.svg";

import t1 from "../../../../assets/images/teachers/t1.webp";
import t2 from "../../../../assets/images/teachers/t2.jpg";
import t3 from "../../../../assets/images/teachers/Photodir.png";

import { HR1 } from "../../../../components/HRs/HRs";
import TitleUndLine from "../../../../components/PageTitles/TitleUndLine/TitleUndLine";
import SeeMore from "../../../../components/SeeMore/SeeMore";

import useResolution from "../../../../hooks/useResolution";

const MeetTheFaculty = ({ in_home = true }) => {
  const { sm } = useResolution();

  return (
    <div className={styles["header"]}>
      <img src={!sm ? t1 : t2} alt="" className={styles["teacher"]} />

      {in_home && (
        <div className={styles["title-container"]}>
          <TitleUndLine color="white">Mot du président-fondateur</TitleUndLine>
        </div>
      )}

      <div className={styles["content"]}>
        <div className={styles["slogon"]}>
          <img src={quote2} alt="" className={styles["quote1"]} />
          <h2>
            L'UIK est née de la volonté de participer à l'effort d'édification
            de la société du savoir de demain.
          </h2>
          <img src={quote2} alt="" className={styles["quote2"]} />
        </div>

        <img src={t3} alt="" className={styles["teacher-img-mobile"]} />

        {in_home && (
          <div className={styles["see-more-container"]}>
            <SeeMore blue_icon2={true} white_text>
              Savoir plus sur notre équipe
            </SeeMore>
          </div>
        )}
      </div>

      <div className={styles["swipper-container"]}>{in_home && <HR1 />}</div>
    </div>
  );
};

export default MeetTheFaculty;
