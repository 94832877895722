import React, { useEffect } from "react";
import styles from "./styles.module.scss";
import RightColoredTitle from "../../../../components/RightColoredTitle/RightColoredTitle";
import { InscrireBtn2 } from "../../../../components/buttons/Buttons";

import Degree from "../../../../components/Cards/Degree/Degree";
import DegreePage from "../../../DegreePage/DegreePage";
import ImgBack from "../../../../components/Image/ImgBack";
import { useNavigate, useParams } from "react-router-dom";

function Ingenierie({
  data = [],
  logo = "",
  defaultTitle = "Université IBN KHALDOUN",
}) {
  const [degree, setDegree] = React.useState(null);

  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    setDegree(data.find((elem) => elem._id === id));
  }, [id]);

  useEffect(() => {
    if (data.length === 1) {
      let deplome = data[0];
      const link = deplome.link.toLowerCase();
      const type = deplome.type;
      const _id = deplome._id;
      navigate(`/university/${link}/${type}/${_id}`);
    }
  }, [data]);

  if (!degree && data.length === 1) {
    return "loading...";
  }

  return (
    <div className={styles["main"]}>
      <div className={styles["header"]}>
        <RightColoredTitle fontSize={40}>
          {degree ? degree.title : defaultTitle}
        </RightColoredTitle>
        <ImgBack src={logo} alt="" className={styles["logo"]} />
      </div>

      {degree && <DegreePage degree={degree} />}

      <div className={styles["body"]}>
        <div className={styles["degrees"]}>
          {data
            .filter((deg) => deg._id !== id)
            .map((deg, key) => {
              return (
                <div key={key} className={styles["degree"]}>
                  <Degree degree={deg} />
                </div>
              );
            })}
        </div>
      </div>

      <div className={styles["button"]}>
        <InscrireBtn2>S'inscrire maintenant !</InscrireBtn2>
      </div>
    </div>
  );
}

export default Ingenierie;
