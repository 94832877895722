import React, { useEffect, useState } from "react";
import ReactFlagsSelect from "react-flags-select";
import styles from "./styles.module.scss";

function Select({ isUp = false }) {
  const [selected, setSelected] = useState("");

  useEffect(() => {
    let lang = localStorage.getItem("lang");
    if (lang) {
      setSelected(lang);
    } else {
      setSelected("FR");
    }
  }, []);

  const HandlelangChange = (e) => {
    localStorage.setItem("lang", e);
    setSelected(e);
    window.location.reload();
  };

  return (
    <div>
      <ReactFlagsSelect
        customLabels={{ US: "English", FR: "Français" }}
        countries={["US", "FR"]}
        className={`${styles.langSelect} ${isUp ? styles.isUp : ""}`}
        showSelectedLabel
        selected={selected}
        onSelect={(code) => HandlelangChange(code)}
        menuPortalTarget={document.body}
        menuPosition={"fixed"}
      />
    </div>
  );
}

export default Select;
