import React from "react";

import styles from "./styles.module.scss";

function Model() {
  return (
    <div className={styles["main"]}>
      <div className={styles["loader"]}>
        <div className={`${styles["box"]} ${styles["box0"]}`}>
          <div></div>
        </div>
        <div className={`${styles["box"]} ${styles["box1"]}`}>
          <div></div>
        </div>
        <div className={`${styles["box"]} ${styles["box2"]}`}>
          <div></div>
        </div>
        <div className={`${styles["box"]} ${styles["box3"]}`}>
          <div></div>
        </div>
        <div className={`${styles["box"]} ${styles["box4"]}`}>
          <div></div>
        </div>
        <div className={`${styles["box"]} ${styles["box5"]}`}>
          <div></div>
        </div>
        <div className={`${styles["box"]} ${styles["box6"]}`}>
          <div></div>
        </div>
        <div className={`${styles["box"]} ${styles["box7"]}`}>
          <div></div>
        </div>
        <div className={styles["ground"]}>
          <div></div>
        </div>
      </div>
      <small>UNDER CONSTRUCTION</small>
      {/* <a
        className={styles["dribbble"]}
        href="https://dribbble.com/shots/7227469-3D-Boxes-Loader"
        target="_blank"
      >
        <img     src="https://dribbble.com/assets/logo-small-2x-9fe74d2ad7b25fba0f50168523c15fda4c35534f9ea0b1011179275383035439.png" />
      </a> */}
    </div>
  );
}

export default Model;
