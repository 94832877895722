import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";

import RightColoredTitle from "../../../../components/RightColoredTitle/RightColoredTitle";

import {
  chef_depart,
  direction,
  teachers,
} from "../../../../assets/data/Teachers";
import TeacherCard from "../../../../components/Cards/TeacherCard/Teachers";
import { InscrireBtn } from "../../../../components/buttons/Buttons";
import { useNavigate } from "react-router-dom";
import axios from "../../../../utils/axios";

function EquipePedago() {
  const navig = useNavigate();

  const [equipe, setEauipe] = useState([]);

  useEffect(() => {
    axios
      .get("/Teacher/getAll")
      .then((res) => {
        console.log(res.data.Person);
        setEauipe(res.data.Person);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const GoToOne = (teach) => {
    if (teach.isDirector) {
      navig("/director");
    } else {
      navig(`/faculty/${teach.link_title}`);
    }
  };

  return (
    <div className={styles["body"]}>
      <div className={styles["container"]}>
        <RightColoredTitle className={styles.title}>
          Direction
        </RightColoredTitle>
        <div className={`${styles["teachers"]}`}>
          {equipe
            .filter(
              (item) =>
                item.position === "Directeur" ||
                item.position === "Directeur General"
            )
            .map((item, key) => {
              return (
                <div className={styles["teacher-container"]} key={key}>
                  <TeacherCard
                    onClick={GoToOne}
                    className={styles["teacher"]}
                    key={key}
                    data={item}
                  />
                </div>
              );
            })}
        </div>

        <RightColoredTitle className={styles.title}>
          Chefs de départements
        </RightColoredTitle>
        <div className={styles["teachers"]}>
          {equipe
            .filter((item) => item.position === "Chef Departement")
            .map((item, key) => {
              return (
                <div
                  className={`${styles["teacher-container"]} ${styles["cards-5"]}`}
                  key={key}
                >
                  <TeacherCard
                    onClick={GoToOne}
                    className={styles["teacher"]}
                    key={key}
                    data={item}
                  />
                </div>
              );
            })}
        </div>

        <RightColoredTitle className={styles.title}>
          Enseignants
        </RightColoredTitle>
        <div className={styles["teachers"]}>
          {equipe
            .filter((item) => item.position === "Professeur")
            .map((item, key) => {
              return (
                <div className={styles["teacher-container"]} key={key}>
                  <TeacherCard
                    onClick={GoToOne}
                    className={styles["teacher"]}
                    key={key}
                    data={item}
                  />
                </div>
              );
            })}
        </div>

        {/* <div className={styles["btn-container"]}>
          <InscrireBtn>Découvrez-en plus sur l'UIK</InscrireBtn>
        </div> */}
      </div>
    </div>
  );
}

export default EquipePedago;
