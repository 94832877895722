import React from "react";
import styles from "./styles.module.scss";

function CardInfo({ Icon, title, children }) {
  return (
    <div className={styles["card-info"]}>
      <div className={styles["head"]}>
        <Icon className={styles["icon"]} />
        <span>{title}</span>
      </div>
      <p>{children}</p>
    </div>
  );
}

export default CardInfo;
