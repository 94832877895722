import React from "react";
import styles from "./styles.module.scss";
import TitleUndLine from "../../../../components/PageTitles/TitleUndLine/TitleUndLine";

import alum_img from "../../../../assets/images/nos_aluminies.webp";
import quote2 from "../../../../assets/svgs/icons/quote2.png";
import SeeMore from "../../../../components/SeeMore/SeeMore";
import { FadeUp } from "../../../../components/Animations/Fade";
import { useNavigate } from "react-router-dom";

function NosAluminies() {
  const navigate = useNavigate();

  const GoToAluminies = () => {
    navigate("/campus/alumni");
  };

  return (
    <div className={styles["main"]}>
      <FadeUp>
        <TitleUndLine>Nos Alumni</TitleUndLine>
      </FadeUp>

      <FadeUp className={styles["container"]}>
        <div className={styles["description"]}>
          <div className={styles["content"]}>
            <img src={quote2} alt="" />
            <h2>Inspiring minds, shaping futures.</h2>
          </div>

          <img
            src={alum_img}
            alt=""
            className={`${styles["image"]} ${styles.mobile}`}
          />

          <div className={styles["see-more"]}>
            <SeeMore blue_icon={true} blue_text1={true} onClick={GoToAluminies}>
              Voir plus sur nos alumni
            </SeeMore>
          </div>
        </div>
        <img
          src={alum_img}
          alt=""
          className={`${styles["image"]} ${styles.pc}`}
        />
      </FadeUp>
    </div>
  );
}

export default NosAluminies;
