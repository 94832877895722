import React from "react";
import styles from "./styles.module.scss";

function PageHeader(props) {
  const { children } = props;
  return (
    <div className={styles.main}>
      <h1> {children} </h1>
    </div>
  );
}

export default PageHeader;
