import React from "react";
import styles from "./da.module.scss";
import LeftColoredTitle from "../../../components/Titles/LeftColoredTitle";

import { ZoomIn } from "../../../components/Animations/Zoom";
import { useNavigate } from "react-router-dom";

function DemarchesAdmission() {
  return (
    <div className={styles.main}>
      <LeftColoredTitle>Démarches d’admission</LeftColoredTitle>

      <div className={styles.hexagonals_container}>
        <div className={styles.hexagonals}>
          <Hexagonal
            step={1}
            title="Trouve ton programme"
            btn_title="Rechercher"
            url="/informatique"
          />
          <Hexagonal
            step={2}
            title="Remplis le formulaire de pré-inscription"
            btn_title="Remplir"
            url="/Inscription"
          />
        </div>
        <div className={styles.hexagonals}>
          <Hexagonal step={3} title="Envoi tes documents" btn_title="" />
          <Hexagonal step={4} title="Entretien" btn_title="" />
          <Hexagonal
            step={5}
            title="Décision du comité d’admission"
            btn_title=""
          />
        </div>

        <div className={styles.hexagonals_mobile}>
          <Hexagonal
            step={1}
            title="Trouve ton programme"
            btn_title="Rechercher"
            url="/informatique"
          />
          <Hexagonal
            step={2}
            title="Remplis le formulaire de pré-inscription"
            btn_title="Remplir"
            url="/Inscription"
          />
          <Hexagonal step={3} title="Envoi tes documents" btn_title="" />

          <Hexagonal step={4} title="Entretien" btn_title="" />
          <Hexagonal
            step={5}
            title="Décision du comité d’admission"
            btn_title=""
          />
        </div>
      </div>
    </div>
  );
}

const Hexagonal = ({ title = "", step = "", btn_title = "", url = "" }) => {
  const navigate = useNavigate();

  const GoToLink = () => {
    navigate(url);
  };

  return (
    <ZoomIn offset={-500} className={styles.hexagonal}>
      <div className={styles.inside}>
        <h2>STEP 0{step}</h2>
        <p>{title}</p>
        {btn_title.length !== 0 && (
          <button onClick={GoToLink}>{btn_title}</button>
        )}
      </div>
    </ZoomIn>
  );
};

export default DemarchesAdmission;
