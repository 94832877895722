import React, { useState } from "react";
import styles from "./styles.module.scss";

import PageContainer from "../../Common/PageContainer/PageContainer";
import LeftColoredTitle from "../../components/Titles/LeftColoredTitle";

import { InscrireBtn } from "../../components/buttons/Buttons";

import { init_data } from "./validation";
import { ContactUsServ } from "../../services/inscription";
import { toast } from "react-hot-toast";

function ContactUs() {
  const [form, setForm] = useState({ ...init_data });

  const handle_change = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handle_submit = () => {
    let data = { ...form, NOM: `${form.Nom} ${form.Prenom}` };
    delete data.Nom;
    delete data.Prenom;
    ContactUsServ(data)
      .then((resp) => {
        setForm({ ...init_data });
        toast.success("message envoyé avec succès");
      })
      .catch((error) => {
        toast.success("Server error");
      });
  };

  return (
    <div>
      <PageContainer title="Contact Us" links={[]} with_links={false}>
        <div className={styles.container}>
          <LeftColoredTitle>Informations générales</LeftColoredTitle>

          <div className={styles.form}>
            <div className={styles.w50}>
              <label> Nom* </label>

              <input value={form.Nom} name="Nom" onChange={handle_change} />
            </div>

            <div className={styles.w50}>
              <label> Prénom* </label>

              <input
                value={form.Prenom}
                name="Prenom"
                onChange={handle_change}
              />
            </div>

            <div className={styles.w50}>
              <label> Email* </label>

              <input value={form.EMAIL} name="EMAIL" onChange={handle_change} />
            </div>

            <div className={styles.w50}>
              <label> Téléphone / Numéro WhatsApp* </label>

              <input
                value={form.TELEPHONE}
                name="TELEPHONE"
                onChange={handle_change}
              />
            </div>

            <div className={styles.w100}>
              <label> Sujet </label>

              <input value={form.obj} name="obj" onChange={handle_change} />
            </div>

            <div className={styles.w100}>
              <label>Message</label>

              <textarea
                value={form.msg}
                onChange={handle_change}
                name="msg"
                rows={5}
              />
            </div>
          </div>
          <div className={styles.send}>
            <InscrireBtn onClick={handle_submit}> Envoyer </InscrireBtn>
          </div>
        </div>
      </PageContainer>
    </div>
  );
}

export default ContactUs;
