import React from "react";
import styes from "./et.module.scss";

import LeftColoredTitle from "../../../components/Titles/LeftColoredTitle";
import international_img from "../../../assets/images/internat.jpg";

function EtudiantInternaux() {
  return (
    <div className={styes.main}>
      <LeftColoredTitle>Etudiants Internationaux</LeftColoredTitle>

      <img src={international_img} alt="" className={styes.full_img} />

      <h2 className={styes.h2}>1 - Procédures administratives et légales</h2>
      <h3 className={styes.h3}>A - Visa</h3>

      <p className={styes.description}>
        L’étudiant étranger doit détenir à son arrivée à l’Université un
        passeport valide et un visa d’entrée. Le visa n’est pas exigé pour les
        ressortissants de certains pays étrangers. Vous devez donc avant votre
        départ vous renseigner auprès du Consulat de Tunisie dans votre pays
        d’origine ou à défaut dans un pays voisin ayant une représentation
        tunisienne. Les documents à fournir pour la demande de visa sont les
        suivants :
      </p>

      <p className={styes.description}>
        - 2 photographies d’identité <br />
        - 3 formulaires de visa remis par le Consulat tunisien <br />
        - Photocopie des 3 premières pages de votre passeport <br />- Photocopie
        de l’attestation d’inscription et du reçu de paiement
      </p>

      <p className={styes.description}>
        Les délais pour obtenir le visa peuvent être longs et varient selon
        votre pays de résidence. Il est donc important d’entreprendre vos
        démarches dès que vous avez reçu votre inscription définitive de
        l’Université. Veillez à formuler votre demande de visa suffisamment tôt.
      </p>

      <h3 className={styes.h3}>B - Carte de Séjour </h3>

      <p className={styes.description}>
        Lorsque vous arrivez à Tunis et vous installez, il est impératif de
        déposer votre demande de carte de séjour dans un délai d'un mois
        maximum. Vous devez vous rendre au commissariat le plus proche de votre
        lieu de résidence pour effectuer cette demande. Les documents requis
        comprennent :
      </p>

      <ul className={styes.ul}>
        <li>
          Votre certificat d'inscription à l'université et le reçu de paiement
          des frais de scolarité.
        </li>
        <li>
          Votre contrat de location, ou si vous résidez en foyer, une
          attestation de résidence.
        </li>
        <li>Deux photos d'identité.</li>
        <li>
          Deux timbres fiscaux que vous pouvez obtenir à la Recette des
          Finances.
        </li>
        <li>
          Un justificatif de ressources bancaires et/ou une attestation de prise
          en charge.
        </li>
        <li>Un justificatif d'assurance maladie/accident.</li>
      </ul>

      <p className={styes.description}>
        La délivrance de la carte de séjour prendra environ 2 à 3 mois à partir
        de la date de dépôt de votre demande. Cette carte sera valide pendant un
        an, jusqu'au 30 septembre de l'année en cours, et vous devrez la
        renouveler chaque année. En attendant, le commissariat vous remettra une
        carte provisoire qui atteste de votre statut régulier sur l'ensemble du
        territoire tunisien.
      </p>

      <h2 className={styes.h2}>2 - Logement provisoire </h2>

      <p className={styes.description}>
        L'université Ibn Khaldoun propose des logements provisoires sur son
        campus aux nouveaux étudiants internationaux, leur offrant ainsi une
        solution pratique en attendant de trouver un logement individuel ou en
        colocation. Le bureau d'aide au logement de l'université se chargera par
        la suite de trouver un logement permanent aux étudiants selon leur
        budgets et leurs préférences.
      </p>

      <h2 className={styes.h2}>3 - Bureau d’aide à la recherche de logement</h2>

      <p className={styes.description}>
        Le bureau d'aide à la recherche de logement de l'université Ibn Khaldoun
        joue un rôle essentiel en trouvant des logements pour les nouveaux
        étudiants. Ce service s'engage à faciliter la transition des étudiants
        en leur offrant des solutions de logement adaptées à leurs besoins. Que
        ce soit en trouvant des chambres dans les résidences universitaires, en
        facilitant la recherche de colocations ou en fournissant des
        informations sur les offres de logements disponibles dans les environs
        de l'université, le bureau d'aide à la recherche de logement s'efforce
        de garantir que chaque nouvel étudiant puisse bénéficier d'un
        hébergement confortable et sûr dès son arrivée. Grâce à ses nombreuses
        connexions avec les propriétaires locaux et son large réseau
        d’étudiants, le bureau d’aide à la recherche de logement se consacre à
        trouver les meilleures options de logement, permettant ainsi aux
        étudiants de se concentrer pleinement sur leurs études et de s'intégrer
        facilement à la vie universitaire.
      </p>

      <h2 className={styes.h2}> 4 - Estimation des dépenses</h2>

      <table>
        <th>
          <td>Dépenses </td>
        </th>
        <th>
          <td>Budget</td>
        </th>
        <tr>
          <td>Logement en colocation </td>
          <td>Entre 70 € et 120 € / Mois</td>
        </tr>

        <tr>
          <td>Logement individuel </td>
          <td>Entre 180 € et 300 € /Mois</td>
        </tr>

        <tr>
          <td>Alimentation </td>
          <td>150 € / Mois</td>
        </tr>

        <tr>
          <td>Transport </td>
          <td>100 € Mois</td>
        </tr>
      </table>
    </div>
  );
}

export default EtudiantInternaux;
