import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import download_icon from "../../../assets/svgs/icons/download.svg";
import Apropos from "./Apropos/Apropos";
import Ingenierie from "./Ingenierie/Ingenierie";
import Books from "./Books/Books";
import PopupInsc from "./PopupInsc/PopupInsc";
import { useLocation, useParams, useNavigate } from "react-router-dom";

function BodyPage({ data }) {
  const [open, setOpen] = useState(false);

  const { type } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const changeUrl = (uri) => {
    let univ = location.pathname.split("/")[2];
    navigate(`/university/${univ}/${uri}`);
  };

  useEffect(() => {
    let allawed = ["a_propos", "licence", "master", "cycle", "dna", "books"];
    let univ = location.pathname.split("/")[2];
    if (!allawed.includes(type)) {
      navigate(`/university/${univ}/a_propos`);
    }
  }, [type, location]);

  const options = [
    {
      text: "A Propos",
      type: "a_propos",
      Component: <Apropos a_propos={data.a_propos} />,
      exist: true,
    },
    {
      text: "Licence",
      type: "licence",
      Component: (
        <Ingenierie
          data={data.licence}
          logo={data.a_propos.logo}
          defaultTitle="Licence"
        />
      ),
      exist: data?.licence?.length,
    },
    {
      text: "Master",
      type: "master",
      Component: (
        <Ingenierie
          data={data.master}
          logo={data.a_propos.logo}
          defaultTitle="Master"
        />
      ),
      exist: data?.master?.length,
    },
    {
      text: "Cycle Ing",
      type: "cycle",
      Component: (
        <Ingenierie
          data={data.cycle}
          logo={data.a_propos.logo}
          defaultTitle="CYCLE INGÉNIEUR INFORMATIQUE"
        />
      ),
      exist: data?.cycle?.length,
    },
    {
      text: "DNA",
      type: "dna",
      Component: (
        <Ingenierie
          data={data.dna}
          logo={data.a_propos.logo}
          defaultTitle="Diplome National D'architecte"
        />
      ),
      exist: data?.dna?.length,
    },
    {
      text: "Books",
      type: "books",
      Component: <Books data={data.books} logo={data.a_propos.logo} />,
      exist: data?.books?.length,
    },
    {
      text: "Brochure",
      type: "a_propos",
      icon: download_icon,
      Component: <Apropos data={data.a_propos} />,
      exist: true,
    },
  ];

  return (
    <div className={styles["main"]}>
      <div className={styles["container"]}>
        <div className={styles["options"]}>
          {options
            .filter((option) => option.exist)
            .map((option, key) => {
              return (
                <Option
                  key={key}
                  option={option}
                  selected={type === option.type}
                  onClick={() => {
                    !option.icon && changeUrl(option.type);

                    if (option.text === "Brochure") {
                      setOpen(true);
                    }
                  }}
                />
              );
            })}
        </div>
        {(options.find((elem) => elem.type === type) || options[0]).Component}
      </div>

      {open && (
        <PopupInsc
          pdfLink={data.brochure}
          close={() => {
            setOpen(false);
          }}
        />
      )}
    </div>
  );
}

const Option = ({ option, selected, onClick }) => {
  const { text, icon } = option;

  return (
    <div
      onClick={onClick}
      className={`
        ${styles["option"]} 
        ${icon ? styles["with-icon"] : ""} 
        ${selected && !icon ? styles["selected"] : ""} 
    `}
    >
      {selected && !icon && <span className={styles["block"]} />}
      {icon && <img src={icon} alt="" />}
      {text}
    </div>
  );
};

export default BodyPage;
