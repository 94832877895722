import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import logo from "../../../../assets/svgs/logos/logo_blue.svg";
import ScrollNow from "../../../../components/ScrollNow/ScrollNow";
import { useRef } from "react";

import temp_img from "../../../../assets/images/home.webp";
import uik_video from "../../../../assets/videos/home.mp4";
import { useLocation } from "react-router-dom";
import useResolution from "../../../../hooks/useResolution";

function VideoSection() {
  const ref = useRef(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const location = useLocation();
  const { sm } = useResolution();

  const ScrollScreen = () => {
    let offset = sm ? 60 : 0;
    window.scrollTo({
      top: (ref?.current.offsetHeight || window.innerHeight) - offset,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("load", () => {
      setIsLoaded(true);
    });

    if (document.readyState === "complete") {
      setIsLoaded(true);
    }

    return () => {
      window.removeEventListener("load", () => {
        setIsLoaded(true);
      });
    };
  }, [location]);

  return (
    <div className={styles["main"]} ref={ref}>
      <div className={styles["video-container"]}>
        {isLoaded && (
          <video autoPlay muted loop className={styles["video"]}>
            <source src={uik_video} type="video/mp4" />
          </video>
        )}
        {!isLoaded && (
          <img src={temp_img} alt="home page img" className={styles["video"]} />
        )}
      </div>
      <div className={styles["filter2"]} />
      <div className={styles["filter1"]} />
      <div className={styles["scroll-indicator"]}>
        <img src={logo} alt="" className={styles.logo} />

        <ScrollNow onClick={ScrollScreen} />
      </div>
    </div>
  );
}

export default VideoSection;
