import React, { useEffect } from "react";
import styles from "./styles.module.scss";
import University from "../../../Common/University/University";
import axios from "../../../utils/axios";
import { useParams, useNavigate } from "react-router-dom";

function UniversityPage() {
  const [data, setData] = React.useState(null);
  const { univ } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    let allawed = [
      "informatique",
      "architecture",
      "design",
      "droit",
      "buiness",
    ];
    if (!allawed.includes(univ)) {
      navigate(`/university/informatique`);
    } else {
      axios
        .get(`/University/getByLink/${univ}`)
        .then((res) => {
          setData(res.data);
        })
        .catch((err) => {});
    }
  }, [univ]);

  return (
    <div className={styles["main"]}>{data && <University data={data} />}</div>
  );
}

export default UniversityPage;
