import axios from "../utils/axios";

export const inscription = (data) => {
  return axios.post("/Person/formInsc", { ...data });
};

export const PreInscription = (data) => {
  return axios.post("/Person/formPotential", { ...data });
};

export const ContactUsServ = (data) => {
  return axios.post("/Person/formContact", { ...data });
};
