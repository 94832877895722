import React from "react";
import styles from "./styles.module.scss";
// import SeeMore from "../../../../components/SeeMore/SeeMore";

// import CountDownOnShow from "../../../../components/CountDown/CountDownInShow";

import { HR1 } from "../../../../components/HRs/HRs";
import { data, data2 } from "./data";

import SeeMore from "../../../../components/SeeMore/SeeMore";
import CountDownOnShow from "../../../../components/CountDown/CountDownInShow";

function BlueSecondV2() {
  return (
    <div className={styles["main"]}>
      <h1 className={styles["title"]}>L’UIK EN CHIFFRES</h1>
      <HR1 className={styles.hr} />

      <div className={styles["parcour"]}>
        <h1 className={styles["number"]}>
          <CountDownOnShow number={25} />
          <span className={styles["plus-mob"]}>+</span>
        </h1>
        <h1 className={styles["plus"]}>+</h1>
        <p>PARCOURS UNIVERSITAIRES</p>
      </div>

      <HR1 className={styles.hr} />

      <div className={styles.items_container}>
        {data.p1.map((item, key) => {
          return <Item key={key} item={item} />;
        })}
      </div>

      <div className={`${styles.items_container} ${styles.items_container2}`}>
        {data.p2.map((item, key) => {
          return <Item key={key} item={item} />;
        })}
      </div>

      <div className={styles.details}>
        {data2.map((item, key) => {
          return <Item2 key={key} item={item} />;
        })}

        <div className={styles.see_more}>
          <SeeMore link="" white_icon={true} white_text={true}>
            savoir plus sur l'UIK
          </SeeMore>
        </div>
      </div>
    </div>
  );
}

const Item = ({ item }) => {
  return (
    <div className={`${styles.item} ${item.blue && styles.blued}`}>
      <img src={item.img} alt="" />
      <p className={styles.number}>{item.numb}</p>
      <p className={styles.desc}>{item.desc}</p>
    </div>
  );
};

const Item2 = ({ item }) => {
  return (
    <div className={styles.item2}>
      <p className={styles.number}>{item.numb}</p>
      <p className={styles.desc}>{item.desc}</p>
    </div>
  );
};

export default BlueSecondV2;
