import React from "react";
import styles from "./styles.module.scss";

import facultie1 from "../../../../assets/images/faculties/1.png";
import facultie2 from "../../../../assets/images/faculties/2.png";
import facultie3 from "../../../../assets/images/faculties/3.png";
import facultie4 from "../../../../assets/images/faculties/4.png";
import facultie5 from "../../../../assets/images/faculties/5.png";

import { ZoomIn } from "../../../../components/Animations/Zoom";
import { NavLink } from "react-router-dom";

function Faculties() {
  return (
    <div className={styles["main"]}>
      <h1 className={styles.title}>Nos écoles</h1>

      <div className={styles.faculties}>
        <ZoomIn className={styles.zoom}>
          <NavLink to="/university/informatique">
            <img src={facultie1} alt="" />
          </NavLink>
        </ZoomIn>

        <ZoomIn className={styles.zoom}>
          <NavLink to="/university/architecture">
            <img src={facultie2} alt="" />
          </NavLink>
        </ZoomIn>

        <ZoomIn className={styles.zoom}>
          <NavLink to="/university/design">
            <img src={facultie3} alt="" />
          </NavLink>
        </ZoomIn>
      </div>

      <div className={styles.faculties}>
        <ZoomIn className={styles.zoom}>
          <NavLink to="/university/droit">
            <img src={facultie4} alt="" />
          </NavLink>
        </ZoomIn>

        <ZoomIn className={styles.zoom}>
          <NavLink to="/university/buiness">
            <img src={facultie5} alt="" />
          </NavLink>
        </ZoomIn>
      </div>

      <div className={styles["faculties-mobile"]}>
        <ZoomIn className={styles.zoom}>
          <NavLink to="/university/informatique">
            <img src={facultie1} alt="" />
          </NavLink>
        </ZoomIn>

        <ZoomIn className={styles.zoom}>
          <NavLink to="/university/architecture">
            <img src={facultie2} alt="" />
          </NavLink>
        </ZoomIn>
      </div>

      <div className={styles["faculties-mobile"]}>
        <ZoomIn className={styles.zoom}>
          <NavLink to="/university/design">
            <img src={facultie3} alt="" />
          </NavLink>
        </ZoomIn>
      </div>

      <div className={styles["faculties-mobile"]}>
        <ZoomIn className={styles.zoom}>
          <NavLink to="/university/droit">
            <img src={facultie4} alt="" />
          </NavLink>
        </ZoomIn>
        <ZoomIn className={styles.zoom}>
          <NavLink to="/university/buiness">
            <img src={facultie5} alt="" />
          </NavLink>
        </ZoomIn>
      </div>
    </div>
  );
}

export default Faculties;
